import React, { useEffect, useState } from "react";

import moment from "moment";

import styled from "styled-components/macro";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField as MuiTextField } from "@material-ui/core";

import memberService from "../../domain/services/solicitation/member.service";
import { Timer } from "@material-ui/icons";
import loginService from "../../domain/services/login.service";

const TextFieldTime = styled(MuiTextField)`
    input[type="time"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
`;

const InternalWorkingTime = (props) => {

    const [open, setOpen] = useState(true);
    const [timeInfo, setTimeInfo] = useState({ time: "00:59" });
    const [petitionInfo, setPetitionInfo] = useState({});

    useEffect(() => getPetitionInfo(), []);

    const getPetitionInfo = async () => {
        const memberType = props.isAnalyst ? "findAnalyst" : "findReviewers";
        const { data } = await memberService[memberType](props.requestId).catch(err => ({ data: [] }));

        const member = data.find(item => item.userId = props.userId);
        if (member && member.petitionInfo)
            setPetitionInfo({ ...member.petitionInfo });
    }

    const save = async () => {
        let timeInMinutes = moment.duration(timeInfo.time, 'HH:mm').asMinutes();

        await memberService.updateInternalPetitionInfo({
            petitionInfo: { ...petitionInfo, timeInMinutes },
            isReviewer: props.isReviewer, isAnalyst: props.isAnalyst,
        }, props.requestId, props.userId);

        props.onSave();
    }

    const handleChange = (time) => {
        setTimeInfo({ time });
    }

    const handleClose = () => {
        setOpen(false);
        props.onCancel();
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} maxWidth="md" disableBackdropClick disableEscapeKeyDown>
                <DialogTitle style={{ paddingBottom: "10px" }}>
                    <Timer style={{ marginBottom: "-3px", fontSize: "18px", color: "#376fd0" }} />{" "} Tempo de {props.isReviewer ? 'Revisão' : 'Indicação de Petição'}
                </DialogTitle>
                <DialogContent>
                    <section style={{ marginTop: "10px", width: "180px" }}>
                        <TextFieldTime label="Informe o tempo" type="time"
                            value={(timeInfo.time || "00:59")}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { handleChange(e.target.value) }}
                            select={false} fullWidth />
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="text" color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={save} variant="text" color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default InternalWorkingTime;