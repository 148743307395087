export const packages = {
    infinity: {
        id: 24,
    },
    premium: {
        id: 23,
    },
    start: {
        id: 20,
    },
};