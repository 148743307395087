import { useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import Button from "./Button";

/**
 * @typedef {Object} Step
 * @property {string} title Título do Passo
 * @property {string} media Imagem ou Gif renderizado à direita do card
 * @property {string} primaryButtonText Texto do botão que passa para o próximo passo
 * @property {boolean} hideBack Esconde o botão de voltar
 */

/**
 * Componente de Tutorial
 * @property {boolean} open 
 * @property {string} title Título do tutorial
 * @property {Step[]} steps Passos do Tutorial
 * @property {function} onClose Função executada ao fechar o Tutorial 
 * @property {function} onFinish Funcção executada ao finalizar o Tutorial
 */
export default function Tutorial({
    open,
    steps = [],
    onClose = () => { },
    onFinish = () => { },
    title,
}) {

    const theme = useTheme();

    const isMobile = useMediaQuery('(max-width:768px)');

    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const currentStep = steps[currentStepIndex];
    const currentStepRef = useRef(null);

    const togglePageScroll = () => document.body.style.overflow = open ? "hidden" : "unset";

    const scrollToElement = () => {
        if (!currentStepRef.current) return;

        const offset = -96;

        const stepNode = currentStepRef.current;
        const parentNode = stepNode.parentNode;

        parentNode.scrollTop = stepNode.offsetTop + offset;
    }

    useEffect(() => {
        togglePageScroll();

        if (!open) {
            setCurrentStepIndex(0);
            return;
        };
    }, [open]);

    useEffect(() => {
        scrollToElement();
    }, [currentStepRef?.current])

    const changeStep = (amount) => {
        const newIndex = currentStepIndex + amount;

        if (newIndex >= steps.length) {
            onFinish();
            return;
        }

        setCurrentStepIndex(newIndex);
    }

    const getPrimaryButtonText = () => {
        if (currentStep.primaryButtonText) return currentStep.primaryButtonText;
        return currentStepIndex < steps.length - 1 ? 'Continuar' : 'Finalizar'
    }

    const renderStep = (step, index) => {
        const isStepOpen = isMobile || currentStepIndex === index;

        return (
            <div
                ref={currentStepIndex === index ? currentStepRef : null}
                className="md:cursor-pointer"
                onClick={() => setCurrentStepIndex(index)}
            >
                <div
                    className="flex font-semibold items-center"
                    style={{
                        opacity: isStepOpen ? 1 : 0.6,
                        transition: 'opacity .3s ease',
                        color: theme.palette.primary.main
                    }}
                >
                    <span className="w-5 text-lg leading-6">{index + 1}</span>
                    <span className="leading-5">{step.title}</span>
                </div>
                <div
                    className="pl-5"
                    style={{
                        marginTop: isStepOpen ? 12 : 0,
                        opacity: isStepOpen ? 1 : 0,
                        height: isStepOpen ? 'auto' : 0,
                        transition: 'opacity .3s ease, margin .3s ease',
                    }}
                >
                    {step.content}
                    <div className="mt-3 mb-6 block md:hidden">
                        <img className="w-full" src={step.media} alt="media" />
                    </div>
                </div>
                {index < steps.length - 1 && (

                    <div
                        className="my-4 w-full h-[1px]"
                        style={{ borderBottom: 'solid 1px #ededed' }}
                    />
                )}
            </div>
        );
    }

    if (!open) return <></>;

    return (
        <div
            className="fixed left-0 top-0 w-screen h-screen bg-[#0006] flex justify-center items-center z-[1000001] box-border"
        >
            <div className="flex flex-col p-6 rounded-lg text-base bg-white w-full max-w-5xl h-full md:h-auto">
                <div className="flex justify-between gap-3">
                    <div className="font-semibold text-lg">{title}</div>
                    <div>
                        <button onClick={onClose}>
                            <Close className="text-[#0009] hover:text-[#000] !transition-[color_.3s_ease,_box-shadow_.3s_ease] hover:shadow-[inset_0px_0_0px_12px_#0001,_0_0_0_1px_#0001]" />
                        </button>
                    </div>
                </div>
                <div className="mt-6 overflow-y-auto">
                    <div className="flex flex-1 md:h-96">
                        <div className="flex flex-col w-full md:w-5/12 md:pr-6 overflow-y-auto relative">
                            {steps.map(renderStep)}
                        </div>
                        <div className="hidden md:block w-7/12 h-96 pl-6">
                            <div className="w-full h-full flex items-center">
                                {!!currentStep.media && (
                                    <img className="w-full" src={currentStep.media} alt="media" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 pt-6 mt-auto justify-end">

                    {currentStepIndex > 0 && !currentStep.hideBack && (
                        <Button
                            type="secondary"
                            onClick={() => changeStep(-1)}
                        >
                            Voltar
                        </Button>
                    )}
                    <Button onClick={() => changeStep(1)}>
                        {getPrimaryButtonText()}
                    </Button>
                </div>
            </div>
        </div>
    );
}