import {
  Avatar, Badge,
  Box as MuiBox,
  Chip, Collapse,
  Drawer as MuiDrawer, Grid, List as MuiList,
  ListItem,
  ListItemText,
  Typography, Tooltip,
  useTheme
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { darken, rgba } from "polished";
import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import loginService from '../domain/services/login.service';
import { getRoutesByRoles as routes } from "../routes/index";
import LogoFacilita from '../facilita-juridico-logo.png'
import { useTour } from "../context/tourContext";
import SellerDailyTargetSidebarPanel from "./crm/crm.seller.daily.target.sidebar.panel";
import { rolesKeys } from "../domain/constant/user.constant";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  height: 100%;
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};  
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  max-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const LogoTag = styled.div`
    background: white;
    height: 86px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.40);
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.background)};

    span {
      color: rgb(97 97 97 / 90%);
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: rgb(97 97 97 / 90%);
    font-size: 14px;
    font-weight: 450;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
  margin-left: 10px;
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
  margin-left: 10px;
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) => darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  padding: 0 ${(props) => props.theme.spacing(9)}px;
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  :first-child { border: unset }
  padding: ${(props) => props.theme.spacing(4)}px 16px ${(props) => props.theme.spacing(1)}px;
  display: block;  
  color: rgb(97 97 97 / 90%);
  font-size: 14px !important;
  font-weight: 600 !important;
  border-top: 1px solid rgb(0 0 0 / 8%);
  width: 100% !important;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const newItemTag = () =>
  <Tooltip title='Item adicionado recentemente!'>
    <Chip
      label='NOVO' color='primary'
      variant='outlined' size='small'
      style={{
        fontSize: '11px', height: '22px', marginTop: '-2px',
        marginRight: '-5px', letterSpacing: '.04em'
      }}
    />
  </Tooltip>

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  isNew,
  tourRef,
  ...rest
}) => {
  return (
    <Category {...rest} ref={tourRef}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {badge ? <CategoryBadge label={badge} /> : ""}
      {isNew ? newItemTag() : ""}
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
    </Category>
  );
};

const SidebarLink = ({ name, to, badge, icon, isNew }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
      {isNew ? newItemTag() : ""}
    </Link>
  );
};

const getRoutes = () => routes(loginService.getUserAuthentication()) || [];

const Sidebar = ({ classes, staticContext, location, ...rest }) => {

  const user = loginService.getUserAuthentication();

  const theme = useTheme();

  const { registerRef } = useTour();

  const tourRefs = {
    requests: registerRef('initialTour', 'requests'),
    customerStatistics: registerRef('initialTour', 'statistics'),
    customersFinance: registerRef('initialTour', 'balance'),
  };

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    getRoutes().forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const getSellerDailyTargetSidebarPanel = () => {

    const isSeller = loginService.isValidRoles([rolesKeys.SELLER]);
    if (!isSeller)
      return null;

    return (
      <SellerDailyTargetSidebarPanel />
    )
  }

  return (
    <Drawer variant="permanent" {...rest}>

      <Brand
        component={NavLink} to="/" button
        style={{ backgroundColor: theme.palette.primary.main, color: '#FFF' }}
        className="!px-6"
      >
        <LogoTag className="pt-5 pb-2 px-2">
            <img
                src={user?.customer?.logo?.url || LogoFacilita}
                alt="Logo Facilita Jurídico"
                className="max-h-full"
            />
        </LogoTag>
      </Brand>

      <Scrollbar>

        {getSellerDailyTargetSidebarPanel()}

        <List disablePadding style={{ marginTop: 10 }}>
          <Items>
            {getRoutes().map((category, index) => (
              <React.Fragment key={index}>
                {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                      isNew={category.isNew}
                    />

                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map((route, index) => (
                        <SidebarLink
                          key={index}
                          name={route.name}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                          isNew={route.isNew}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : category.icon ? (
                  <>
                    <SidebarCategory
                      tourRef={tourRefs[category.id] || null}
                      isCollapsable={false}
                      name={category.name}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      button
                      badge={category.badge}
                      isNew={category.isNew}
                    />
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container spacing={2}>
          <Grid item>
            <SidebarFooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={user?.name}
                src="/static/img/avatars/avatar-1.jpg"
              />
            </SidebarFooterBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">
              {user?.name}
            </SidebarFooterText>
            <SidebarFooterSubText variant="caption" style={{ fontSize: "9px" }}>
              {user?.email}
            </SidebarFooterSubText>
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);
