import React, { useState, useEffect } from "react";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import styled from "styled-components/macro";
import moment from "moment";

import Helmet from "react-helmet";

import {
    CardContent, Grid, Card as MuiCard, Table, TableBody, TableCell,
    TableHead, TableRow, Typography as MuiTypography, Chip, TableSortLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { spacing, display } from "@material-ui/system";
import { MonetizationOn } from "@material-ui/icons";

import InvoiceDocument from "./invoice.document.component";
import LoadingProgress from "../LoadingProgress";

import utilsProvider from "../providers/utils.provider";

import loginService from "../../domain/services/login.service";
import CorrespondentService from "../../domain/services/correspondent.service";

const Card = styled(MuiCard)`${spacing}; box-shadow: none;`;

const Shadow = styled.div`box-shadow: ${(props) => props.theme.shadows[1]};`;

const Typography = styled(MuiTypography)(display);

function InvoiceDetails(props) {

    const [requests, setRequests] = useState([]);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("firstConcluded");
    const { promiseInProgress } = usePromiseTracker();

    const userId = (
        new URL(window.location.href).searchParams.get("userId") ||
        loginService.getUserAuthentication().id
    );

    useEffect(() => trackPromise(list()), []);

    moment.locale('pt');

    moment.updateLocale('pt', {
        months: [
            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
            "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
        ]
    });

    const columns = [
        { id: "requestId", label: "ID Solicitação", order: true },
        { id: "firstConcluded", label: "1° Conclusão", order: true },
        { id: "price", label: "Preço", order: true },
        { id: "faciliterServiceDescription", label: "Serviço", order: true }
    ];

    const requestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    }

    const list = async () => {

        const { data } = await CorrespondentService.getInvoiceRequests(userId, props.value.id).catch(error => ({ data: [] }));

        setRequests([...data.requests]);
    }

    const loading = () => promiseInProgress && (<LoadingProgress />);

    return (
        <React.Fragment>
            <Helmet title="Detalhes da Fatura" />

            <Grid container justify="center">
                <Grid item>
                    <Shadow>
                        <Card px={6} pt={6}>
                            <CardContent>
                                <Chip label={props.value.closed ? 'Fechada' : 'Aberta'}
                                    style={{
                                        float: 'left', color: 'white', fontWeight: '600',
                                        fontSize: '12px', height: '17px',
                                        background: !props.value.closed ? "#376fd0" : "green"
                                    }}
                                />

                                <Typography variant="h4"
                                    style={{ float: 'right', fontSize: "14px", fontWeight: "600" }}>
                                    <MonetizationOn style={{ marginBottom: "-6px", color: "gold" }} />
                                    {" Valor:"}{utilsProvider.formatNumberCurrency(props.value.priceToPay)}
                                </Typography>

                                <Grid container spacing={6}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom style={{ fontWeight: "400" }}>
                                            Segue os detalhes da fatura N° #{props.value.id} de {moment(props.value.created).format('MMMM [de] YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="caption" align="left" display="block">
                                            Aberto Em
                                        </Typography>
                                        <Typography variant="body2" align="left">
                                            {moment(props.value.created).format('DD [de] MMMM [de] YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="caption" align="left" display="block">
                                            Fechado Em
                                        </Typography>
                                        <Typography variant="body2" align="left">
                                            {!!props.value.closed ? moment(props.value.closed).format('DD [de] MMMM [de] YYYY') : "Em Aberto"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="caption" align="left" display="block">
                                            <InvoiceDocument userId={userId} invoiceId={props.value.id} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {loading()}

                        {!promiseInProgress && (requests.length ?
                            <Table style={{ background: "white" }}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((item) => (
                                            <TableCell key={item.id} sortDirection={orderBy === item.id ? order : false}>
                                                {
                                                    item.order ?
                                                        <TableSortLabel
                                                            active={orderBy === item.id}
                                                            direction={orderBy === item.id ? order : "asc"}
                                                            onClick={() => { requestSort(item.id) }}>
                                                            {item.label}
                                                        </TableSortLabel> : <span>{item.label}</span>
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {utilsProvider
                                        .sortList(requests, utilsProvider.getComparator(order, orderBy))
                                        .map((item, i) => (
                                            <TableRow
                                                key={i} className="MuiTableRow-hover"
                                                style={{ backgroundColor: item.price < 0 && '#f7dfdf' }}
                                            >
                                                <TableCell>
                                                    <Typography style={{
                                                        color: "rgb(0 0 0 / 75%)",
                                                        fontWeight: "600", width: "30px"
                                                    }}>
                                                        {item.requestId}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {item.delayed || !item.firstConcluded ? '-' : moment(item.firstConcluded).format('DD/MM/YYYY HH:mm')}
                                                </TableCell>
                                                <TableCell>
                                                    {item.invoiceId === item.faciliterInvoiceId && utilsProvider.formatNumberCurrency(item.price)}
                                                    {item.tipValue > 0 && item.invoiceId === item.tipFaciliterInvoiceId &&
                                                        <>
                                                            <Typography variant="body1" style={{ color: 'green' }}>
                                                                {`+ ${utilsProvider.formatNumberCurrency(item.tipValue)} de gorjeta`}
                                                            </Typography>
                                                            <Typography variant="caption" style={{ color: 'grey' }}>
                                                                {`${utilsProvider.formatNumberCurrency(parseFloat(item.tipTotal) / parseFloat(item.tipDivisionFactor))} dado pelo cliente -
                                                                ${utilsProvider.formatNumberCurrency(
                                                                    (parseFloat(item.tipTotal) - parseFloat(item.tipDivisionFactor) * parseFloat(item.tipValue)) / parseFloat(item.tipDivisionFactor)
                                                                    )} de taxa`}
                                                            </Typography>
                                                        </>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {item.faciliterServiceDescription}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table> :
                            <Card style={{ marginTop: "10px", borderRadius: "0", backgroundColor: "unset" }}>
                                <Alert mt={2} mb={1} severity="info">
                                    Não há registros
                                </Alert>
                            </Card>
                        )}
                    </Shadow>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default InvoiceDetails;
