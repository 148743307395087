import { Box, Grid, Collapse } from '@material-ui/core';

import AudioRecorder from './audio.recorder.field';
import FormFieldTitle from '../../FormFieldTitle';
import AudioRecorderButton from './audio.recorder.button';

import useRequestAudioRecorder from '../../../hooks/request/useRequestAudioRecorder';

const RequestField = (props) => {

    const { field, request, setValue, fieldsWithError = [], isReadOnly, fieldProps = {},
        getElementByFieldType, getFieldOptions, getFieldValue, getSetFieldValue, isEdit } = props;

    const {
        showRecordButton, showAudioRecorder, handleStartRecording, isLoading, ...audioRecorderProps
    } = useRequestAudioRecorder(request.id, field);

    const element = getElementByFieldType(isReadOnly ? "labelView" : field.type);
    const fieldName = field.isService ? field.name : (field.key || field.name);

    const getFieldComponent = (hideLabel) => (
        <element.component
            {...props} {...field}
            label={showAudioRecorder ? 'Observações' : !hideLabel && field.label}
            extraInfo={showAudioRecorder ? 'Adicione observações se necessário' : field.extraInfo}
            required={showAudioRecorder ? false : field.required}
            name={fieldName}
            fieldServiceName={field.name}
            requestId={request.id}
            value={getFieldValue(field, fieldName, request)}
            isEdit={isEdit}
            setFieldValue={getSetFieldValue(field, setValue, request)}
            getOptions={getFieldOptions}
            onError={fieldsWithError.includes(fieldName)}
            fieldProps={{
                ...fieldProps,
                titleColor: showAudioRecorder ? 'gray' : fieldProps.titleColor,
                titleSize: showAudioRecorder ? 12 : fieldProps.titleSize,
                titleWeight: showAudioRecorder ? 'normal' : fieldProps.titleWeight,
            }}
        />
    )

    if (!field.acceptAudioAnswer)
        return getFieldComponent();

    return (
        <Grid item>

            <Box
                display='flex' alignItems='center'
                style={{ marginBottom: fieldProps.spacing || 0 }}
            >

                <FormFieldTitle
                    {...props} {...field} {...fieldProps}
                    spacing={false}
                    field={field.label}
                    titleSize={15}
                    titleWeight={600}
                />

                {showRecordButton && (
                    <Box sx={{ ml: 2 }}>
                        <AudioRecorderButton
                            handleStartRecording={handleStartRecording}
                            isLoading={isLoading}
                            disabled={showAudioRecorder}
                            isNewRequest={request.status === 'NEW'}
                        />
                    </Box>
                )}

            </Box>

            <Collapse in={showAudioRecorder} mountOnEnter>
                <Box sx={{ mb: 4 }}>
                    <AudioRecorder
                        requestId={request.id}
                        isNewRequest={request.status === 'NEW'}
                        field={field}
                        {...audioRecorderProps}
                    />
                </Box>
            </Collapse>

            {getFieldComponent(true)}

        </Grid>
    );
}

export default RequestField;
