import { Cancel, KeyboardArrowDown } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import LoadingSpin from "../LoadingSpin";

/**
 * Componente genérico de select
 * @property {array} options Recebe um array de objetos no formato [{ label, value, disabled? }]
 * @property {function} onSelect Função que executa ao selecionar, passa como argumento o valor e index selecionado (value, index) 
 * @property {string} title (opcional) Título que é exibido acima do input
 * @property {string} placeholder (opcional) Placeholder do input
 * @property {bool} loading Estado de loading
 * @property {bool} allowClear Permite limpar o select após selecionar uma opção
 * @property {function} getRef Função para pegar referência do botão, assim é possível abrir ele programaticamente
 * @example
 * <CustomSelect
 *      title="Opções"
 *      options={[
 *          { label: 'Opção A', value: 634 },
 *          { label: 'Opção B', value: 1234 },
 *          { label: 'Opção C', value: 85, disabled: true },
 *      ]}
 *      onSelect={(value, index) => console.log(value, index)}
 *      placeholder="Seleciona a opção"
 *      loading={loading}
 *      allowClear
 * />
 */
export default function CustomSelect({
    options = [],
    onSelect = () => { },
    title = null,
    placeholder = null,
    loading = false,
    allowClear = false,
    getRef = ref => {},
    value,
}) {

    const ref = useRef();

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleSelect = (event, selectedValue, index) => {
        event.stopPropagation();
        setSelectedIndex(index);

        setTimeout(() => {
            setOpen(false);
        }, 300);

        onSelect(selectedValue, index);
    }

    const handleOutSideClick = (event) => {
        const isOutsideClick = ref.current && !ref.current.contains(event.target);
        if (isOutsideClick && open) {
            setOpen(false);
        }
    }

    useEffect(() => {
        const optionIndex = options.findIndex(option => option.value === value);

        if (optionIndex === -1) {
            setSelectedIndex(null);
            return;
        };

        setSelectedIndex(optionIndex);
    }, [value]);

    useEffect(() => {
        if (ref.current) {
            getRef(ref.current);
        }
    }, [ref]);
    
    useEffect(() => {
        document.addEventListener("mousedown", handleOutSideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [ref, open]);

    return (
        <div className="text-sm w-full">
            {title && <div className="font-semibold mb-2">{title}</div>}
            <button
                ref={ref}
                className="relative h-8 bg-[#fff] border-solid border border-[#ededed] hover:border-[#376fd0] rounded px-3 w-full transition-[border-color_.2s_ease]"
                style={{ borderColor: open ? '#376fd0' : undefined }}
                onClick={() => !loading && setOpen(prev => !prev)}
            >

                <div className="flex flex-wrap pr-4 relative items-center">

                    {selectedIndex === null && <div className="text-[#0006]">{placeholder}</div>}

                    {selectedIndex != null && <div className="overflow-hidden text-ellipsis text-nowrap pr-2">{options[selectedIndex].label}</div>}
                    <div className="absolute right-0">
                        {loading ? (
                            <LoadingSpin />
                        ) : allowClear && selectedIndex != null ? (
                            <button onClick={e => handleSelect(e, null, null)}>
                                <Cancel className="text-[#0007] hover:text-[#000a]" />
                            </button>
                        ) : (
                            <KeyboardArrowDown className="text-[#0005]" />
                        )}
                    </div>
                </div>

                <div
                    className="absolute left-0 top-[calc(100%+8px)] w-full bg-[#fff] shadow-[0_0_12px_#0004] p-1 rounded z-[9999] max-h-56 overflow-auto"
                    style={{
                        pointerEvents: open ? 'all' : 'none',
                        opacity: open ? 1 : 0,
                        transformOrigin: 'top',
                        transform: open ? 'scaleY(1)' : 'scaleY(0.85)',
                        transition: 'opacity .2s ease, transform .2s ease',
                    }}
                >

                    {options.map((option, index) => (
                        <button
                            className="bg-transparent hover:bg-[#0001] px-3 py-2 w-full text-left rounded"
                            style={{
                                cursor: option.disabled ? 'not-allowed' : undefined,
                                color: option.disabled ? '#0006' : undefined,
                                backgroundColor: option.disabled ? 'transparent' : index === selectedIndex ? '#376fd022' : undefined,
                                transition: 'background-color .2s ease',
                            }}
                            onClick={e => !option.disabled && handleSelect(e, option.value, index)}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>

            </button>
        </div>
    );
}