import { useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState, useEffect } from "react";

/**
 * @typedef {Object} Step
 * @property {string} title Título do Passo
 * @property {string} content Conteúdo renderizado no passo. Pode ser um texto ou um elemento
 * @property {function} target Uma função no formato que retorne o elemento a ser destacado. Caso não seja informado o passo é exibido no centro da tela
 * @property {'top' | 'right' | 'bottom' | 'left'} placement Posicionamento do card relativo ao elemento em target
 * @property {boolean} enableInteraction Permite clicar e interagir com o elemento destacado
 * @property {string} primaryButtonText Texto do botão que passa para o próximo passo
 * @property {string} overlay Cor no overlay exibido no fundo
 * @property {number} overlayOpacity Número entre 0 e 1 para definir a opacidade do overlay
 * @property {boolean} triggerNext Valor que ao mudar para true, passa para o próxmimo passo. Útil quando é necessário passar para o próximo passo baseado numa ação do usuário, como clicar no elmento destacado
 * @property {boolean} hideBack Esconde o botão de voltar
 * @property {boolean} showButtons Caso falso, esconde todos os botões de navegação. A navegação para o próximo passo fica dependente do triggerNext
 * @property {boolean} disableNext Desabilita o botão de continuar
 * @property {function} onNextAction Função para ser executada ao clicar no botão de continuar. A função passa como parâmetro um callback que ao executar passa para o próximo passo.
 */

/**
 * Componente de Tour
 * @property {boolean} open 
 * @property {Step[]} steps Passos do Tour
 * @property {function} onClose Função executada ao fechar o Tour 
 * @property {function} onFinish Função executada ao finalizar o Tour
 * @property {number} containerWidth Largura do card 
 * @property {number} highlightPadding Padding do elemento destacado
 * @property {number} containerOffset Distância do card ao elemento destacado 
 */
export default function Tour({
    open,
    steps,
    onClose = () => { },
    onFinish = () => { },
    containerWidth = 520,
    highlightPadding: highlightPaddingProp = 4,
    containerOffset = 24,
}) {

    const defaultPosition = {
        left: window.innerWidth / 2,
        top: window.innerHeight / 2,
        width: 0,
        height: 0,
    };

    const theme = useTheme();

    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [scrollPositioned, setScrollPositioned] = useState(false);

    const currentStep = steps[currentStepIndex];
    const componentRef = currentStep.target && currentStep.target() ? currentStep.target() : null;
    const componentPos = componentRef ? componentRef.getBoundingClientRect() : defaultPosition;

    const containerMargin = componentRef ? containerOffset : 0;
    const highlightPadding = componentRef ? highlightPaddingProp : 0;

    const scrollIntoView = () => {
        if (!componentRef) return;
        window.scrollTo(window.scrollX, componentRef.offsetTop - window.innerHeight / 2);
    }

    const togglePageScroll = disableScroll => document.body.style.overflow = disableScroll ? "hidden" : "unset";

    useEffect(() => {
        return () => {
            togglePageScroll(false);
        }
    }, []);

    useEffect(() => {

        togglePageScroll(open);

        setScrollPositioned(false);

        if (!open) {
            setCurrentStepIndex(0);
            return;
        };

        scrollIntoView();

        setScrollPositioned(true);

    }, [open, componentRef]);

    const changeStep = (amount) => {
        const newIndex = currentStepIndex + amount;

        if (newIndex >= steps.length) {
            onFinish();
            return;
        }

        setCurrentStepIndex(newIndex);
        setScrollPositioned(false);
    }

    const handleGoToNext = () => {
        if (!currentStep.onNextAction) {
            changeStep(1);
            return;
        }

        currentStep.onNextAction(() => changeStep(1));
    }

    useEffect(() => {
        if (!currentStep.triggerNext) return;
        changeStep(1);
    }, [currentStep.triggerNext]);

    const renderOverlay = () => {

        const overlayColor = currentStep.overlay || '#000';
        const overlayOpacity = currentStep.overlayOpacity = 0.5;

        const commonStyle = {
            position: 'fixed',
            backgroundColor: overlayColor,
            zIndex: 9999,
            top: 0,
            left: 0,
            transition: 'all .3s ease',
        };

        const sides = [
            {
                key: 'top',
                left: componentPos.left - highlightPadding,
                width: componentPos.width + highlightPadding * 2,
                height: componentPos.top - highlightPadding,
            },
            {
                key: 'right',
                left: componentPos.left + componentPos.width + highlightPadding,
                width: `calc(100% - ${componentPos.left + componentPos.width + highlightPadding}px)`,
                height: '100%',
            },
            {
                key: 'bottom',
                top: componentPos.top + componentPos.height + highlightPadding,
                left: componentPos.left - highlightPadding,
                width: componentPos.width + highlightPadding * 2,
                height: `calc(100% - ${componentPos.top + componentPos.height + highlightPadding}px)`,
            },
            {
                key: 'left',
                width: Math.max(componentPos.left - highlightPadding, 0),
                height: '100%',
            },
        ];

        return (
            <div
                style={{ opacity: overlayOpacity, position: 'fixed', zIndex: 9999 }}
            >
                {sides.map(side => (
                    <div key={side.key} style={{ ...commonStyle, ...side }} />
                ))}
            </div>
        )
    }

    const getContainerPosition = (refDimensions) => {
        const placement = componentRef && currentStep.placement ? currentStep.placement : 'top';

        const placements = {
            right: () => ({
                left: refDimensions.left + refDimensions.width + containerMargin,
                top: refDimensions.top + refDimensions.height / 2,
                transform: 'translateY(-50%)',
            }),
            bottom: () => {
                const leftPos = Math.max(refDimensions.left + refDimensions.width / 2 - containerWidth / 2, 0);
                const rightOverflowAmount = Math.max((leftPos + containerWidth) - window.innerWidth, 0);
                return {
                    top: refDimensions.top + refDimensions.height + containerMargin,
                    left: Math.max(leftPos - rightOverflowAmount, 0),
                };
            },
            left: () => ({
                left: refDimensions.left - containerMargin - containerWidth,
                top: refDimensions.top + refDimensions.height / 2,
                transform: 'translateY(-50%)',
            }),
            top: () => {
                const leftPos = Math.max(refDimensions.left + refDimensions.width / 2 - containerWidth / 2, 0);
                const rightOverflowAmount = Math.max((leftPos + containerWidth) - window.innerWidth, 0);
                return {
                    top: refDimensions.top - containerMargin,
                    left: Math.max(leftPos - rightOverflowAmount, 0),
                    transform: 'translateY(-100%)',
                }
            }
        };

        return placements[placement]();

    }

    const getArrowPosition = (refDimensions) => {
        const placement = componentRef && currentStep.placement ? currentStep.placement : 'top';

        const placements = {
            right: () => ({
                top: refDimensions.top + refDimensions.height / 2,
                left: refDimensions.left + refDimensions.width + containerMargin,
            }),
            bottom: () => ({
                top: refDimensions.top + refDimensions.height + containerMargin,
                left: refDimensions.left + refDimensions.width / 2,
            }),
            left: () => ({
                top: refDimensions.top + refDimensions.height / 2,
                left: refDimensions.left - containerMargin,
            }),
            top: () => ({
                top: refDimensions.top - containerMargin,
                left: refDimensions.left + refDimensions.width / 2,
            }),

        }

        return placements[placement]();
    }

    const getRefDimensions = () => {
        const refDimensions = {
            width: componentPos.width + highlightPadding * 2,
            height: componentPos.height + highlightPadding * 2,
            top: componentPos.top - highlightPadding,
            left: componentPos.left - highlightPadding,
        };

        return refDimensions;
    }

    const getPrimaryButtonText = () => {
        if (currentStep.primaryButtonText) return currentStep.primaryButtonText;
        return currentStepIndex < steps.length - 1 ? 'Continuar' : 'Finalizar'
    }

    const renderContentContainer = () => {

        const refDimensions = getRefDimensions();
        const arrowPosition = getArrowPosition(refDimensions);
        const containerDimensions = getContainerPosition(refDimensions);

        const enableInteraction = !!currentStep.enableInteraction;

        return (
            <div
                className="fixed w-full h-full left-0 top-0 z-[10000]"
                style={{ pointerEvents: enableInteraction ? 'none' : 'all' }}
            >
                <div
                    className="w-3 h-3 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white rounded"
                    style={{
                        position: 'fixed',
                        zIndex: 10000,
                        display: componentRef ? 'block' : 'none',
                        ...arrowPosition,
                    }}
                />
                <div
                    className="max-w-[100vw] pointer-events-auto"
                    style={{
                        position: 'fixed',
                        zIndex: 10000,
                        width: containerWidth,
                        ...containerDimensions,
                    }}
                >
                    <div className="p-8 rounded-lg text-base bg-white">
                        <div className="flex justify-between gap-3">
                            <div className="font-semibold">{currentStep.title}</div>
                            <div>
                                <button onClick={onClose}>
                                    <Close className="text-[#0009] hover:text-[#000] !transition-[color_.3s_ease,_box-shadow_.3s_ease] hover:shadow-[inset_0px_0_0px_12px_#0001,_0_0_0_1px_#0001]" />
                                </button>
                            </div>
                        </div>
                        <div className="mt-3">{currentStep.content}</div>
                        <div className="flex justify-between mt-4">
                            <div className="flex gap-2 items-center min-h-7 h-auto">
                                {steps.map((step, index) => (
                                    <div
                                        className="h-2 w-2 rounded-full shrink-0"
                                        style={{
                                            background: currentStepIndex === index ? theme.palette.primary.main : '#0003',
                                        }}
                                    />
                                ))}
                            </div>
                            <div className="flex gap-2">
                                {currentStep.showButtons !== false && (
                                    <>
                                        {currentStepIndex > 0 && !currentStep.hideBack && (
                                            <button
                                                className="px-2 py-1 text-sm rounded" style={{ border: 'solid 1px #dedede' }}
                                                onClick={() => changeStep(-1)}
                                            >
                                                Voltar
                                            </button>
                                        )}
                                        <button
                                            className="px-2 py-1 text-sm rounded text-white"
                                            onClick={handleGoToNext}
                                            style={{
                                                background: theme.palette.primary.main,
                                                pointerEvents: currentStep.disableNext ? 'none' : undefined,
                                                filter: currentStep.disableNext ? 'saturate(0)' : undefined,
                                            }}
                                        >
                                            {getPrimaryButtonText()}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (!open) return <></>;

    return (
        <>
            {renderOverlay()}
            {scrollPositioned && renderContentContainer()}
        </>
    );
}