import { useEffect, useState } from "react";

import requestAdvanceService from '../../../domain/services/request/advance.service';
import CustomSelect from "../../shared/CustomSelect";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Typography } from "@material-ui/core";

import advanceService from "../../../domain/services/request/advance.service";

export default function AdvanceRequestForm({
    mainRequestId,
    onSelect,
    selectValue,
    getAntecipationButtonRef,
}) {

    const [loading, setLoading] = useState(false);
    const [advanceOptions, setAdvanceOptions] = useState([]);

    const hasAdvanceOptions = advanceOptions.length > 0;
    const hasBalanceToAdvance = advanceOptions.length > 0 && advanceOptions.filter(a => a.billing).length > 0;

    const loadAdvanceOptions = async () => {
        setLoading(true);
        await requestAdvanceService.findAvailableByMainId(mainRequestId)
            .then(setAdvanceOptions)
            .finally(() => setLoading(false));
    }

    const handleSelect = (value, index) => {
        onSelect(advanceOptions[index] || null);
    }

    useEffect(() => {
        loadAdvanceOptions();
    }, []);

    const options = advanceOptions.map(option => ({
        label: advanceService.getAdvanceLabel(option),
        value: option.id,
        disabled: !option.billing,
    }))

    return (
        <div>
            <div className="font-semibold mb-2">Em quantos dias você quer receber a solicitação?</div>

            {loading || (hasAdvanceOptions && hasBalanceToAdvance) ? (
                <CustomSelect
                    options={options}
                    placeholder="Selecione a quantidade de dias"
                    onSelect={handleSelect}
                    loading={loading}
                    getRef={getAntecipationButtonRef}
                    value={selectValue}
                />
            ) : hasAdvanceOptions && !hasBalanceToAdvance ? (
                <Alert severity='error'>
                    <AlertTitle style={{ fontWeight: 'bold', marginBottom: 10 }}>Você não possui saldo para antecipar um prazo de entrega!</AlertTitle>

                    <Typography variant='subtitle2'>
                        Realize a compra de saldo na loja e antecipe o prazo de entrega nos detalhes da solicitação
                    </Typography>
                </Alert>
            ) : (
                <Alert severity='error'>
                    <AlertTitle style={{ fontWeight: 'bold', marginBottom: 10 }}>Não existem opções de antecipações disponíveis para essa solicitação!</AlertTitle>
                </Alert>
            )}
        </div>
    )
}