import React from 'react';

import styled, { withTheme } from 'styled-components/macro';
import { spacing } from '@material-ui/system';

import {
    Grid,
    AppBar as MuiAppBar,
    Toolbar,
    Box as MuiBox,
    ListItem,
    IconButton,
    Hidden,
    useTheme,
} from '@material-ui/core';

import UserLogout from './UserLogout';

import Notification from './Notification';
import { CreateOutlined, Menu as MenuIcon, ShoppingCartOutlined } from '@material-ui/icons';
import loginService from '../domain/services/login.service';
import { Link } from 'react-router-dom';

import LogoFacilita from '../facilita-juridico-logo.png'
import { useTour } from '../context/tourContext';


const Box = styled(MuiBox)(spacing);

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.hideSideBar ? '#376fd0 !important' : props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: '${(props) => props.theme.sidebar.header.background}';
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;



const AppBarComponent = ({ hideSideBar = false, ...props }) => {

    const theme = useTheme();

    const { registerRef } = useTour();
    const newRequestRef = registerRef('initialTour', 'newRequest');

    return (
        <React.Fragment>
            <AppBar position='fixed' elevation={0} hideSideBar={hideSideBar}>
                <Toolbar>
                    {props?.isExternal ? (
                        <>
                            <Grid container spacing={0} justifyContent='center'>
                                <Grid item md={6}>
                                    <Brand
                                        button
                                    >
                                        <Box ml={1}> {props?.isLink ? 'Vinculo Solicitação' : 'Novo Cadastro'} </Box>
                                    </Brand>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}

                    {props?.isExternal ? null : (
                        <Grid
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <div>
                                {!hideSideBar ? (

                                    <Hidden mdUp={props.hidden ? true : false}>
                                        <Grid item>
                                            <IconButton
                                                color='inherit'
                                                style={{ position: 'fixed', left: '10px', marginTop: '-24px' }}
                                                onClick={props.onDrawerToggle}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        </Grid>
                                    </Hidden>
                                ) : (
                                    <div
                                        className="fixed top-0 left-[25px] h-[75px] max-w-[210px] bg-white z-[1101]"
                                        style={{
                                            borderBottomLeftRadius: 8,
                                            borderBottomRightRadius: 8,
                                            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 6px',
                                        }}
                                    >
                                        <div className='flex items-center justify-center'>
                                            <Link to="/home" className='max-h-full max-w-full'>
                                                <div className='h-[75px] p-2 max-w-full'>
                                                    <img
                                                        src={LogoFacilita}
                                                        alt="Logo Facilita Jurídico"
                                                        className="max-h-full"
                                                    />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {props.editorMenu && props.editorMenu}

                            <div className='flex items-center gap-3'>
                                {(loginService.isInternal() || loginService.isFaciliter()) && <Notification />}

                                {loginService.isCustomerAdmin() && (
                                    <Link to="/marketplace">
                                        <button
                                            className='px-3 py-2 rounded font-bold bg-[#28A745] text-white transition-[filter_.3s_ease] hover:brightness-110 border-black'
                                        >
                                            <div className='flex gap-2 items-center text-sm'>
                                                <ShoppingCartOutlined className='!text-xl' />
                                                Comprar Agora
                                            </div>
                                        </button>
                                    </Link>
                                )}

                                <Hidden only={["xs", "sm"]}>
                                    {loginService.isCustomer() && (
                                        <Link to="/solicitations#new">
                                            <button
                                                className='px-3 py-2 rounded font-bold bg-white transition-[filter_.3s_ease] hover:brightness-110'
                                                ref={newRequestRef}
                                                style={{ color: theme.palette.primary.main }}
                                            >
                                                <div className='flex gap-2 items-center text-sm'>
                                                    <CreateOutlined className='!text-xl' />
                                                    Nova Solicitação
                                                </div>
                                            </button>
                                        </Link>
                                    )}
                                </Hidden>

                                <UserLogout />
                            </div>

                        </Grid>
                    )}

                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default withTheme(AppBarComponent);
