import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { getValidatedRoutesLayout, routesLayout } from './index';

import loginService from '../domain/services/login.service';
import Providers from '../context/providers';

const selectRoutes = (Layout, routes) =>
    routes.map((item, index) => {

        const Guard = item?.guard || React.Fragment;

        return item?.children ? (
            item.children.map((element, index) => {
                const Guard = element.guard || React.Fragment;

                return (
                    <Route
                        key={index}
                        path={element.path}
                        exact
                        render={(props) => (
                            <Guard>
                                <Layout {...item.layoutProps}>
                                    <element.component {...props} />
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })
        ) : item?.component ? (
            <Route
                key={index}
                path={item.path}
                exact
                render={(props) => (
                    <Guard>
                        <Layout {...item.layoutProps}>
                            <item.component {...props} {...item.props} />
                        </Layout>
                    </Guard>
                )}
            />
        ) : null;
    });

const create = () => getValidatedRoutesLayout().map((layout) => selectRoutes(layout.component, layout.routes));

const getHomePageBySource = () => {

    if (loginService.isCustomer())
        return "/home"

    if (loginService.isValidRoles(['external-seller']))
        return "/customers"

    return "/solicitations";
}

const Routes = () => (
    <Router>
        <Providers>
            <Switch>
                {create()}
                <Route
                    render={() =>
                        !loginService.getToken() ? (
                            <Redirect to="/login" />
                        ) : (
                            <Redirect to={getHomePageBySource()} />
                        )
                    }
                />
            </Switch>
        </Providers>
    </Router>
);

export default Routes;
