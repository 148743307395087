import { Typography } from "@material-ui/core";
import { AccessTimeOutlined, Check } from "@material-ui/icons";

import datetimeProvider from "../../providers/datetime.provider";

import loginService from "../../domain/services/login.service";

export default function RequestServiceInformationHearingDateInfo({
    dateTimeInfo,
    isCheckin
}) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>

            <Typography style={{ color: '#000000DE' }}>
                Data e Hora {isCheckin ? 'do Check-in' : 'da Audiência'} {isCheckin && !loginService.isFaciliter() ? 'do Failiter' : ''}
            </Typography>

            <Typography color="secondary" style={{
                fontWeight: 500, fontSize: 20,
                display: 'flex', alignItems: 'end', lineHeight: 1.22, paddingTop: 4
            }}>

                {isCheckin ?
                    <Check style={{ marginTop: 2, marginRight: 4 }} />
                    : <AccessTimeOutlined style={{ marginTop: 2, marginRight: 4 }} />
                }

                <span style={{ marginRight: 8 }}>
                    {datetimeProvider.formatDateTime(dateTimeInfo, 'DD/MM [às] HH:mm', true)}
                </span>

                {isCheckin ? null : (
                    <Typography variant='body2' style={{ color: '#0006' }}>
                        {datetimeProvider.getTimeDifferenceUntilDate(datetimeProvider.getDate(), dateTimeInfo, 'Audiência já realizada.')}
                    </Typography>
                )}

            </Typography>

        </div>
    )
}