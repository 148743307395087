import { Dialog, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export default function SimpleModal({
    title,
    showClose = true,
    onClose = () => { },
    children,
    open = false,
    style,
    bodyStyle,
    maxWidth = 'md',
    subtitle,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    fullWidth = true
}) {

    const handleClose = (event, reason) => {
        if (disableBackdropClick && reason === 'backdropClick')
            return;

        onClose();
    }

    return (
        <Dialog
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={disableEscapeKeyDown}
            PaperProps={{
                square: true,
                style: {
                    overflow: 'visible',
                    borderRadius: 4,
                    ...style,
                }
            }}
        >
            {title && (
                <div className="flex justify-between p-4 md:px-8 md:py-6" style={{ borderBottom: 'solid 1px #d5d5d5' }}>
                    <div className="flex flex-col md:flex-row gap-4 items-baseline">
                        <div style={{ fontSize: 20, fontWeight: 600 }}>
                            {title}
                        </div>
                        {subtitle && <span className="text-base text-[#0009]">{subtitle}</span>}
                    </div>

                    <div>
                        {showClose && (
                            <IconButton
                                aria-label="close"
                                size='small'
                                onClick={onClose}
                            >
                                <Close />
                            </IconButton>
                        )}
                    </div>

                </div>
            )}

            <div className={`relative p-4 md:p-8 md:${title ? '!pt-6' : 'pt-8'} overflow-auto`} style={bodyStyle}>
                {children}
            </div>
        </Dialog>
    )
}