import React, { useEffect, useState } from 'react';

import {
    Button,
    IconButton,
    TextField as MuiTextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Snackbar,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Checkbox
} from '@material-ui/core';
import { Close, InsertDriveFileOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import requestHearingService from '../../../domain/services/request/request.hearing.service';
import stepsSolicitation from '../../../domain/constant/steps.solicitation.constant';

import fields from '../../../domain/constant/request.hearing.fields.constant';

const useStyles = makeStyles((theme) => ({
    button: {
        pointerEvents: 'all',
        fontWeight: 600,
        fontSize: 12,
        padding: '5px 10px'
    },
    buttonSpan: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    loadingIcon: {
        color: 'greenyellow',
        width: 15,
        marginLeft: 10
    },
    dialogHeader: {
        background: "rgb(238 238 238 / 50%)",
        borderBottom: "3px solid rgb(25, 118, 210)"
    },
    dialogContent: {
        padding: '30px 10px'
    },
    dialogActions: {
        padding: 15,
        background: "rgb(238 238 238 / 50%)",
        borderTop: "3px solid rgb(25, 118, 210)"
    }
}));

const DateTimeField = ({ name, label, values, onChange, fieldsWithErrors }) => {
    return (
        <Grid item md={5} xs={12} style={{ marginBottom: '30px' }}>
            <FormControl fullWidth>

                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <DateTimePicker
                        name={name}
                        label={label}
                        minutesStep={5}
                        okLabel="Confirmar"
                        fullWidth
                        cancelLabel="Cancelar"
                        format="dd/MM/yyyy HH:mm"
                        required
                        value={values[name] || null}
                        onChange={(value) => onChange(name, value)}
                        error={fieldsWithErrors.find(f => f.key == name)}
                    />
                </MuiPickersUtilsProvider>

            </FormControl>
        </Grid >
    )
}

const BooleanField = ({ name, label, values, onChange, options }) => {
    return (
        <Grid item md={12} xs={12} style={{ marginBottom: '30px' }}>
            <FormControl fullWidth>

                <FormLabel>{label}</FormLabel>
                <RadioGroup
                    row
                    name={name}
                    value={values[name]}
                    onChange={(e) => onChange(name, e.target.value.toString())}
                >
                    {options.map((option, index) => (
                        <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />
                    ))}
                </RadioGroup>

            </FormControl>
        </Grid >
    )
}

const TextField = ({ name, label, values, onChange, fieldsWithErrors }) => {
    return (
        <Grid item md={12} xs={12} style={{ marginBottom: '30px' }}>
            <FormControl fullWidth>

                <MuiTextField
                    name={name}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label={label}
                    multiline
                    variant='outlined'
                    value={values[name] || ''}
                    onChange={(e) => onChange(name, e.target.value)}
                    error={fieldsWithErrors.find(f => f.key == name)}
                />

            </FormControl>
        </Grid>
    )
}

const CheckBoxField = ({ name, label, values, onChange, fieldsWithErrors }) => {
    return (
        <Grid item md={12} xs={12} style={{ marginBottom: '30px' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={values[name] === 'true'}
                        onChange={(e) => onChange(name, e.target.checked.toString())}
                        error={fieldsWithErrors.find(f => f.key == name)}
                    />
                }
                label={label}
            />
        </Grid>
    )
}

const inputComponentByFieldType = {
    'datetime': DateTimeField,
    'boolean': BooleanField,
    'text': TextField,
    'checkbox': CheckBoxField
}

const RequestHearingForm = ({ request }) => {

    const classes = useStyles();

    const [values, setValues] = useState(false);
    const [fieldsWithErrors, setFieldsWithErrors] = useState([]);

    const [loading, setLoading] = useState(false);
    const [statusMsg, setStatusMsg] = useState({ open: false, text: '', severity: 'warning' })
    const [openDialog, setOpenDialog] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!request.id)
            return

        setLoading(true)
        requestHearingService.findByRequestId(request.id)
            .then(setValues)
            .catch((err) => ({}))
            .finally(() => setLoading(false))
    }, [request?.id])

    const save = () => {

        if (saving)
            return

        setSaving(true);
        requestHearingService.save(request.id, values)
            .then(() => setStatusMsg({ open: true, text: 'Salvo com sucesso!', severity: 'success' }))
            .catch(err => setStatusMsg({ open: true, text: 'Erro ao salvar! Contate o suporte', severity: 'error' }))
            .finally(() => setSaving(false))
    }

    const disableStyle = () => {

        if (![stepsSolicitation.CONCLUDED, stepsSolicitation.CORRECTION].includes(request.status))
            return {}

        return { pointerEvents: 'none', opacity: 1, cursor: 'not-allowed' };
    }

    const onChange = (key, value) => {
        setValues({ ...values, [key]: value })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Button
                    onClick={() => setOpenDialog(true)}
                    variant="contained"
                    color="primary"
                    startIcon={<InsertDriveFileOutlined stle={{ fontSize: "15px" }} />}
                    className={classes.button}
                >
                    <span className={classes.buttonSpan}>
                        <Typography variant='button'>Formulário de Audiência</Typography>
                        {loading && <CircularProgress size={15} className={classes.loadingIcon} />}
                    </span>
                </Button>
            </Grid>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle className={classes.dialogHeader}>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Typography variant="h4">
                            Formulário de Audiência
                        </Typography>
                        <IconButton onClick={() => setOpenDialog(false)}>
                            <Close />
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Grid container justifyContent='space-between' className={classes.dialogContent} style={disableStyle()}>

                        {fields.map(field => {
                            const InputComponent = inputComponentByFieldType[field.type];

                            return (!field.dependencyField || values[field.dependencyField] == field.dependencyValue) && (
                                <InputComponent
                                    key={field.key}
                                    name={field.key}
                                    label={field.label}
                                    values={values}
                                    onChange={onChange}
                                    fieldsWithErrors={fieldsWithErrors}
                                    options={field.options}
                                />
                            )
                        })}

                    </Grid>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>

                    <Button onClick={() => setOpenDialog(false)} variant="contained">
                        Fechar
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                    >
                        Salvar
                    </Button>

                </DialogActions>

            </Dialog >

            <Snackbar
                onClose={() => setStatusMsg({ ...statusMsg, open: false })}
                open={statusMsg.open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={statusMsg.severity}
                >
                    {statusMsg.text}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default RequestHearingForm;
