import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField,
    Typography, DialogContentText, CircularProgress,
} from "@material-ui/core";

import moment from 'moment';
import { useState } from 'react';

import requestDeadlineService from '../domain/services/request/request.deadline.service'

export default function ExtendDueDateModal({ open, onClose, request, setFieldValue, setAlert }) {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState("");
    const [operationalDueDateToSendToUpdate, setOperationalDueDateToSendToUpdate] = useState("");
    const [responseDate, setResponseDate] = useState("");

    const [modalType, setModalType] = useState('initial')
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const minDate = new Date(request.operationalDueDate).toISOString().slice(0, 10);
    const faciliterName = JSON.parse(localStorage.getItem('@facilita-app/user')).name;
    const userId = JSON.parse(localStorage.getItem('@facilita-app/user')).id;
    const intendedDate = moment(`${selectedDate} ${selectedTime}`).format('YYYY-MM-DD HH:mm');

    const handleCheckToExtendDeadline = async () => {
        setIsLoading(true)

        if (moment(intendedDate).isBefore(moment(new Date()))) {
            setIsLoading(false)
            return setIsError(true)
        }

        try {
            const response = await requestDeadlineService.checkExtensionDeadlinePossibility(request.id, userId, intendedDate)

            setResponseDate(moment(response.data).format('DD/MM/YYYY [às] HH:mm'))
            setOperationalDueDateToSendToUpdate(response.data)
            handleModalType(response.data)
            setIsLoading(false)
        }
        catch (error) {
            setAlert(error?.response?.data?.message || 'Não é mais possível solicitar prorrogação', 'error');
            clearModalData()
            setTimeout(() => handleClose(), 4000);
        }
    }

    const handleModalType = (responseDate) => {
        if (responseDate !== intendedDate) return setModalType('denied')
        setModalType('aproved')
    }

    const handleClose = async () => {
        await onClose()
        clearModalData()
    }

    const handleConfirmAction = async () => {

        setIsLoading(true);

        setFieldValue("operationalDueDate", operationalDueDateToSendToUpdate);

        await requestDeadlineService
            .updateOperationalDueDateByExtension(request.id, userId, operationalDueDateToSendToUpdate)
            .then(() => {
                setIsLoading(false);
                handleClose();
                setAlert('Salvo com sucesso', 'success');
            })
            .catch(() => setAlert('Erro ao Salvar', 'error'))
            .finally(() => setIsLoading(false));
    }

    const clearModalData = async () => {
        setSelectedDate('')
        setSelectedTime('')
        setIsError(false)
        setIsLoading(false)
        setModalType('initial')
    }

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const getInitialModal = () => {
        return (
            <>
                <DialogTitle>
                    Olá, {faciliterName}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText >
                        Parece que você precisa de mais tempo para concluir esta solicitação.
                    </DialogContentText>
                    <DialogContentText style={{ color: 'red' }}>
                        Lembre-se que você não poderá pedir outra prorrogação pelas próximas 5 entregas.
                    </DialogContentText>
                    <DialogContentText>
                        Para solicitar uma prorrogação do prazo de entrega, por favor, insira a data e o horário desejados nos campos abaixo:
                    </DialogContentText>

                    <TextField
                        type="date"
                        label="Data Desejada:"
                        value={selectedDate}
                        inputProps={{ min: minDate }}
                        onChange={handleDateChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                    />
                    {
                        isError ?
                            <Typography style={{ color: 'red' }}> Data inválida para prorrogação</Typography>
                            : ""
                    }
                    <TextField
                        type="time"
                        label="Hora Desejada:"
                        value={selectedTime}
                        onChange={handleTimeChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        margin="normal"
                    />

                    <DialogContentText>
                        Após inserir a data e o horário, clique em "Enviar" para enviar sua solicitação.
                        Nossa plataforma analisará sua solicitação e informará se a prorrogação é possível.
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={handleClose} disabled={isLoading}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={handleCheckToExtendDeadline} color="primary" disabled={isLoading}>
                        {isLoading ?
                            <span>
                                <CircularProgress m={2} style={{ marginLeft: '5px', width: '16px', height: '16px' }} />
                            </span> : "Enviar"
                        }
                    </Button>
                </DialogActions>
            </>
        );
    }

    const getAprovedModal = () => {
        return (
            <>
                <DialogTitle>
                    {faciliterName}
                </DialogTitle>

                <DialogContent>
                    <Typography style={{ marginBottom: '1rem', fontSize: '0.9rem' }}>
                        Sua solicitação de prorrogação do prazo de entrega foi aprovada!
                    </Typography>
                    <Typography>
                        A nova data e horário de entrega são:
                    </Typography>
                    <Typography style={{ marginTop: '1rem', marginBottom: '1rem', fontSize: '1rem' }}>
                        {responseDate}
                    </Typography>
                    <DialogContentText>
                        Por favor, certifique-se de cumprir o novo prazo para evitar penalidades e garantir a satisfação do cliente.
                    </DialogContentText>
                    <DialogContentText>
                        Obrigado por sua colaboração e dedicação!
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={handleClose} disabled={isLoading}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={handleConfirmAction} color="primary" disable={isLoading}>
                        {isLoading ?
                            <span>
                                <CircularProgress m={2} style={{ marginLeft: '5px', width: '16px', height: '16px' }} />
                            </span> : "Confirmar"
                        }
                    </Button>
                </DialogActions>
            </>
        );
    }

    const getDeniedModal = () => {
        return (
            <>
                <DialogTitle>
                    {faciliterName}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText >
                        Infelizmente, não foi possível aprovar a prorrogação do prazo de entrega solicitada,
                        pois excederia o prazo estabelecido para o cliente.
                    </DialogContentText>
                    <Typography>
                        O prazo máximo de prorrogação sugerido pelo sistema é:
                    </Typography>
                    <Typography style={{ marginTop: '1rem', marginBottom: '1rem', fontSize: '1rem' }}>
                        {responseDate}
                    </Typography>
                    <DialogContentText>
                        Clique em "Confirmar" para aceitar o prazo sugerido ou entre em contato
                        com nossa equipe de suporte se precisar de mais informações
                    </DialogContentText>
                    <DialogContentText>
                        Lembre-se de que a não entrega do serviço pode gerar multas e até mesmo o desligamento do sistema.
                    </DialogContentText>
                    <DialogContentText>
                        Obrigado por sua compreensão e colaboração!
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" onClick={handleConfirmAction} color="primary" disabled={isLoading}>
                        {isLoading ?
                            <span>
                                <CircularProgress m={2} style={{ marginLeft: '5px', width: '16px', height: '16px' }} />
                            </span> : "Confirmar"
                        }
                    </Button>
                    <a href='https://wa.me/5515998526603'>
                        <Button variant="contained" >
                            Falar com o Suporte
                        </Button>
                    </a>
                </DialogActions>

            </>
        );
    }

    const ModalTypes = {
        initial: getInitialModal,
        aproved: getAprovedModal,
        denied: getDeniedModal,
    }

    return (
        <Dialog
            open={open} maxWidth="xs" fullWidth
            onClose={(e, reason) => reason != 'backdropClick' && handleClose}
        >

            {ModalTypes[modalType]()}

        </Dialog>
    );
}
