import React, { useEffect, useState } from 'react';

import styled from 'styled-components/macro';

import {
    Button as MuiButton, Dialog, DialogTitle, Typography, IconButton, FormLabel, RadioGroup, FormControlLabel, Radio,
    DialogContent, DialogActions, Grid, FormControl, TextField as MuiTextField, Checkbox
} from '@material-ui/core';
import { Close, Assignment, InsertDriveFile } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import fields from '../../../domain/constant/request.hearing.fields.constant';

import requestHearingService from '../../../domain/services/request/request.hearing.service';

const useStyles = makeStyles((theme) => ({
    loadingIcon: {
        color: 'greenyellow',
        width: 15,
        marginLeft: 10
    },
    dialogHeader: {
        background: "rgb(238 238 238 / 50%)",
        borderBottom: "3px solid rgb(25, 118, 210)"
    },
    dialogContent: {
        padding: '30px 10px'
    },
    dialogActions: {
        padding: 15,
        background: "rgb(238 238 238 / 50%)",
        borderTop: "3px solid rgb(25, 118, 210)"
    }
}));

const Button = styled(MuiButton)`
    span {
        justify-content: flex-start;
        svg {
            margin-right: 10px;
        }
    }
`;

const DateTimeField = ({ name, label, values }) => {
    return (
        <Grid item md={5} style={{ marginBottom: '30px' }}>
            <FormControl fullWidth>

                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <DateTimePicker
                        name={name}
                        label={label}
                        minutesStep={5}
                        okLabel="Confirmar"
                        fullWidth
                        cancelLabel="Cancelar"
                        format="dd/MM/yyyy HH:mm"
                        required
                        value={values[name]}
                    />
                </MuiPickersUtilsProvider>

            </FormControl>
        </Grid >
    )
}

const BooleanField = ({ name, label, values, options }) => {
    return (
        <Grid item md={12} style={{ marginBottom: '30px' }}>
            <FormControl fullWidth>

                <FormLabel>{label}</FormLabel>
                <RadioGroup
                    row
                    name={name}
                    value={values[name]}
                >
                    {options.map((option, index) => (
                        <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />
                    ))}
                </RadioGroup>

            </FormControl>
        </Grid >
    )
}

const TextField = ({ name, label, values }) => {
    return (
        <Grid item md={12} style={{ marginBottom: '30px' }}>
            <FormControl fullWidth>

                <MuiTextField
                    name={name}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label={label}
                    multiline
                    variant='outlined'
                    value={values[name] || ''}
                />

            </FormControl>
        </Grid>
    )
}

const CheckBoxField = ({ name, label, values }) => {
    return (
        <Grid item md={12} style={{ marginBottom: '30px' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={values[name] === 'true'}
                    />
                }
                label={label}
            />
        </Grid>
    )
}

const inputComponentByFieldType = {
    'datetime': DateTimeField,
    'boolean': BooleanField,
    'text': TextField,
    'checkbox': CheckBoxField
}

const RequestHearingAnalysisCustomerView = ({ requestId, buttonWidth, buttonVariant, buttonTitle }) => {

    const classes = useStyles();

    const [showAnalysis, setShowAnalysis] = useState(false);
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState([]);

    useEffect(() => {
        if (!requestId)
            return

        setLoading(true)
        requestHearingService.findByRequestId(requestId)
            .then(setValues)
            .catch((err) => ({}))
            .finally(() => setLoading(false))
    }, [requestId])

    return (
        <>
            <Button
                size="small" color="primary" variant="outlined"
                onClick={() => setShowAnalysis(true)}
            >
                {buttonTitle}
            </Button>

            <Dialog open={showAnalysis} maxWidth='md' fullWidth onClose={() => setShowAnalysis(false)}>
                <DialogTitle style={{ display: 'inline-flex', backgroundColor: '#eee', width: '100%', minWidth: '100%' }}>
                    <Typography
                        variant="h4"
                        style={{
                            paddingTop: '12px',
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            minWidth: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Assignment style={{ color: '#376fd0', fontSize: '25px', marginRight: '7px' }} />
                        Análise da audiência
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowAnalysis(false)}
                        style={{ position: 'absolute', right: 0, top: 0, margin: '12px' }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Grid container justifyContent='space-between' className={classes.dialogContent} style={{ pointerEvents: 'none', opacity: 1, cursor: 'not-allowed' }}>

                        {fields.map(field => {
                            const InputComponent = inputComponentByFieldType[field.type];

                            return (!field.dependencyField || values[field.dependencyField] == field.dependencyValue) && (
                                <InputComponent
                                    key={field.key}
                                    name={field.key}
                                    label={field.label}
                                    values={values}
                                    options={field.options}
                                />
                            )
                        })}

                    </Grid>
                </DialogContent>

                <DialogActions style={{ background: "rgb(238, 238, 238)" }}>
                    <Button variant='contained' onClick={() => setShowAnalysis(false)}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RequestHearingAnalysisCustomerView;
