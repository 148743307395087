import { useEffect, useState } from 'react';

import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
    Grid, Typography as MuiTypography, withWidth, isWidthDown,
    Button as MuiButton, Card, withStyles, Box, Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FastForward } from "@material-ui/icons";

import CheckAzul from '../../check_azul_sem_sombra.png';
import NewRequestButton from "../../components/requests/new.request.button.component";

import swal from "sweetalert2";

import loginService from "../../domain/services/login.service";
import customerService from "../../domain/services/customer/customer.service";
import ModalInviteWhatsapp from '../../components/marketplace/checkout/modalInviteWhatsapp';
import announcementService from '../../domain/services/marketing/announcement.service';
import { announcements } from '../../domain/constant/announcements.constant';

const Typography = withStyles(() => ({
    root: { fontFamily: 'satoshi, sans-serif' },
}))(MuiTypography);

const Button = withStyles(() => ({
    root: {
        fontFamily: 'satoshi, sans-serif',
        fontWeight: '900',
        letterSpacing: '.5px',
        borderRadius: '3px',
    }
}))(MuiButton);

const PurchaseThank = ({ width, isRegister }) => {

    const [alertStatus, setAlertStatus] = useState({ open: false });
    const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);

    const history = useHistory();

    const isMobile = () => isWidthDown("xs", width);

    const setAlert = (text, severity = 'success') =>
        setAlertStatus({ text, severity, date: new Date(), open: true })

    useEffect(() => {
        customerService.findById(user.customer?.id)
            .then((res) => handleRecurrence(res.data));
    }, [])

    const user = loginService.getUserAuthentication();

    const openWhatsAppModal = () => {
        const alreadyShowedWhatsappInvite = announcementService.isShowed(announcements.postPurchaseWhatsAppInvite);

        if (alreadyShowedWhatsappInvite) {
            return;
        }

        setShowWhatsAppModal(true);
    }

    const handleRecurrence = (customer) => {

        if (customer || customer?.purchaseRecurrence?.isActive) {
            openWhatsAppModal();
            return
        }

        swal.fire({
            title: 'Sua compra foi concluída com sucesso!',
            html: `
            <div style="text-align: left;">
                <p>Ei Doutor, espera um pouquinho, veja que legal a nova funcionalidade que lançamos - A Recompra Inteligente.</p>
                <p>Com ela você não precisará mais realizar compras todas as vezes que acabar seu saldo, o que te toma tempo e acaba atrasando a entrega da suas solicitações.</p>
                <p>Na Recompra Inteligente você cadastra o seu cartão de crédito uma única vez e, todas as vezes que seu saldo estiver com apenas 1 petição, será efetuada, de forma automática, a recompra de um pacote de acordo com a sua escolha!</p>
                <p>Como você é um cliente super especial, se ativar essa funcionalidade agora, terá ${user.customer?.purchaseRecurrenceBenefit?.value?.description} em todas as compras que fizer pela recompra inteligente!</p>
                <p>E não se preocupe, você tem total controle. Caso queira alterar o tipo de pacote adquirido ou até mesmo cancelar a funcionalidade, você pode fazer isso a qualquer momento, de forma simples e rápida.<p>
                <p>Então, o que acha de ganhar mais tempo e dinheiro com a Facilita? Ative agora mesmo a Recompra Inteligente e aproveite todos os benefícios que ela oferece.</p>
            </div>`,
            icon: 'success',
            confirmButtonText: 'Sim, vou ativar agora a Recompra Inteligente!',
            cancelButtonText: 'Não quero ativar agora',
            showCancelButton: true,
        })
            .then(result => {
                if (result.isConfirmed) {
                    window.location.href = `/profile#financial`
                    return
                }

                openWhatsAppModal();

            })
    }


    return (
        <>
            <Helmet title="Obrigado" />

            <Grid container justifyContent="center">

                <Grid item container md={9} style={{ marginBottom: '4%' }}>
                    <Typography
                        variant="h1" color="primary"
                        style={{
                            marginTop: isMobile() && '15px',
                            fontSize: '40px',
                            fontWeight: '900',
                            fontFamily: 'satoshi, sans-serif'
                        }}
                    >
                        Compra finalizada
                    </Typography>
                </Grid>

                <Grid
                    item container spacing={6} direction="column"
                    justify="center" alignItems="center"
                >
                    <Grid item>
                        <Card style={{
                            boxShadow: '0 0 6px rgba(0, 0, 0, 0.20)',
                            paddingTop: '5%', height: 'auto', borderRadius: '20px',
                        }}>
                            <Grid
                                container direction="column" spacing={1}
                                alignItems="center" justifyContent="center"
                            >

                                <Grid item>
                                    <Typography
                                        variant="h3" color="primary"
                                        style={{ fontWeight: '900' }}
                                    >
                                        Agradecemos sua compra!
                                    </Typography>
                                </Grid>

                                <Grid item md={7}>
                                    <Typography
                                        variant="h4" color="primary" align="center"
                                    >
                                        Cadastre agora a sua {
                                            isRegister ? <strong>primeira solicitação</strong> : 'solicitação'
                                        } em nossa plataforma
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Box
                                        component='img'
                                        alt='Logo Facilita Jurídico'
                                        src={CheckAzul}
                                        sx={{ width: 160, marginY: 5 }}
                                    />
                                </Grid>

                                <Grid container justifyContent='center' item style={{ width: '100%', marginBottom: 10 }}>
                                    <Button
                                        type='button' variant='contained' color='primary'
                                        aria-controls='newRequestModal'
                                        style={{ height: 36 }}
                                        onClick={() => window.location.href = '/solicitations#new'}
                                    >
                                        Cadastrar Solicitação
                                    </Button>
                                </Grid>

                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Button
                            variant='contained' color='primary'
                            onClick={() => history.push('/home')}
                        >
                            IR PARA O INÍCIO
                            <FastForward style={{ marginLeft: '8px' }} />
                        </Button>
                    </Grid>
                </Grid>

            </Grid>

            <Snackbar
                onClose={() => setAlertStatus({ ...alertStatus, open: false })}
                open={alertStatus.open} autoHideDuration={2000} key={alertStatus.date}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity={alertStatus.severity}>
                    {alertStatus.text}
                </Alert>
            </Snackbar>

            <ModalInviteWhatsapp
                open={showWhatsAppModal}
                onClose={() => setShowWhatsAppModal(false)}
            />
        </>
    );
}

export default withWidth()(PurchaseThank);