import { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Button, CircularProgress, Grid } from '@material-ui/core';

import RequestMainInformation from './request.main.information.component';

import communicationProvider from '../../providers/communication.provider'

import requestRegistryService from '../../domain/services/solicitation/request.registry.service';

import swal from 'sweetalert2';
import { requestServices } from '../../domain/constant/request.services.constant';

const NewRequestModalForm = ({ isSubmitting, setIsSubmitting, setAlert, initialValue = null, onClose }) => {

    const [request, setRequest] = useState({});

    const [fieldsWithError, setFieldsWithError] = useState([]);

    const history = useHistory();

    const getFieldProps = () => ({ titleSize: 14, cleanTitle: true, spacing: 8, disabled: isSubmitting });

    const checkHearingAnalysis = () => [requestServices.HEARINGINPERSON, requestServices.HEARING].includes(request.mainServiceKey || request.serviceKey) && !request.forwardToHearingAnalysis;

    const redirect = requestId => {

        const isHearingWithoutAnalysis = checkHearingAnalysis(request);

        history.push({
            pathname: `/solicitations/new/${requestId}`,
            state: { showHearingAnalysisModal: isHearingWithoutAnalysis },
        });
    }

    const handleConfirm = async () => {

        const missingFields = requestRegistryService.getMissingFieldsToStartRegistration(request);

        if (missingFields.length > 0) {
            setAlert('Preencha todos os campos para continuar!', 'error');
            setFieldsWithError(missingFields);
            return;
        }

        setIsSubmitting(true);

        await requestRegistryService
            .initRegistry(request)
            .then((savedRequest) => {

                setIsSubmitting(false);

                if (!savedRequest.canLoadByProcessNumber)
                    return redirect(savedRequest.id);

                onClose();

                return swal.fire({
                    title: 'Identificamos outra solicitação com o mesmo número de processo.',
                    html: `
                        <p>Você gostaria de importar as informações dessa solicitação para acelerar o processo de cadastro ?</p>
                        <p>Apenas anexe o processo atualizado para garantir uma análise precisa, ok ?</p>`,
                    icon: 'warning',
                    confirmButtonText: 'Desejo agilizar meu cadastro',
                    cancelButtonText: 'Quero cadastrar todas as informações',
                    showCancelButton: true,
                    reverseButtons: true
                })
                    .then(async result => {

                        if (!result.isConfirmed)
                            return redirect(savedRequest.id);

                        setIsSubmitting(true);
                        await requestRegistryService.loadFieldsByProcessNumber(savedRequest.id);
                        setIsSubmitting(false);

                        redirect(savedRequest.id);
                    })
            })
            .catch(err => {
                communicationProvider.getErrorMessage(err, setAlert);
                setIsSubmitting(false);
            });
    }

    const changeRequestValues = (changes) => {
        const newRequest = { ...request, ...changes };
        changeRequest(newRequest);
    }

    const changeRequest = (newRequest) => {
        setRequest(newRequest);
        setFieldsWithError([]);
    }

    return (
        <>

            <Grid>
                <RequestMainInformation
                    request={request}
                    setRequest={changeRequest}
                    setMultipleValues={changeRequestValues}
                    fieldProps={getFieldProps()}
                    fieldsWithError={fieldsWithError}
                    setValue={(field, value) => changeRequestValues({ [field]: value })}
                    isModal
                    initialValue={initialValue}
                />
            </Grid>

            <Button
                color='primary'
                variant='contained'
                size='large'
                fullWidth
                style={{ fontWeight: 'bold' }}
                disabled={isSubmitting}
                onClick={handleConfirm}
                className='!mt-3'
            >
                {
                    !isSubmitting
                        ? 'Confirmar'
                        : <CircularProgress size={24} />
                }
            </Button>

        </ >
    );
}

export default NewRequestModalForm;