import axios from 'axios';
import qs from 'qs';
import EventEmmiter from '../../../providers/event.emmiter';
import loginService from '../login.service';

export const events = new EventEmmiter();

export const api = (type = 'user', additionalConfigs = {}) => {
    const paths = {
        'user': 'https://southamerica-east1-facilita-juridico.cloudfunctions.net/api/'
        //'user': 'https://southamerica-east1-facilita-juridico.cloudfunctions.net/api_test/'
        //'user': 'http://localhost:5001/facilita-juridico/southamerica-east1/api',
    };

    const API = axios.create({
        baseURL: paths[type],
        headers: {
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'key': 'AIzaSyAIBy-H_w3KK1E2VuU-SMVzo6JZa3Oz3KM'
        },
        ...additionalConfigs,
    });

    // Return all http/s codes to response
    API.interceptors.response.use(undefined, (err) => {
        const {
            config,
            response: { status },
        } = err;
        return Promise.reject({
            status: status,
            config: config,
            response: err.response,
        });
    });

    // Inject header Authorization
    API.interceptors.request.use(
        (config) => {
            if (config.url.indexOf('users/authenticate') != -1) return config;

            const token = loginService.getToken();
            if (token) config.headers['api-key'] = `${token}`;
            return config;

            //Authorization
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    return API;
};

export const get = async (config = {}) => {
    const { type, service, queryString } = config;

    let queryStr = '';
    if (Object.keys(queryString || []).length > 0)
        queryStr = `?${qs.stringify(queryString, { encodeValuesOnly: true })}`;

    return await wrapperRequest(api(type).get(`${service}${queryStr}`));
};

export const put = async (config = {}) => {
    const { type, service, data } = config;
    return await wrapperRequest(api(type).put(service, data));
};

export const post = async (config = {}) => {
    const { type, service, data, params, additionalConfigs } = config;
    return await wrapperRequest(api(type, additionalConfigs).post(service, data, { params }));
};

export const patch = async (config = {}) => {
    const { type, service, data } = config;
    return await wrapperRequest(api(type).patch(service, data));
};

export const remove = async (params = {}) => {
    const { type, service, data } = params;
    return await wrapperRequest(api(type).delete(service, data));
};

const wrapperRequest = async (requestPromise) => {
    try {
        events.emmit('onLoad', true);
        let response = await requestPromise;
        events.emmit('onLoad', false);
        return response;
    } catch (error) {
        events.emmit('onLoad', false);
        if (error.status === 401) {
            loginService.saveAccess();
            loginService.signOut();
            console.error(error);
            window.location.href = '/login';
        }
        throw error;
    }
};

const extractValueByKey = (key, response = { value: {} }, defaultReturn = []) => {
    if (!response.data[key]) return defaultReturn;

    return response.data[key];
};

export default {
    api,
    get,
    post,
    put,
    remove,
    extractValueByKey,
};