export const newRequestTutorialSteps = [
    {
        title: 'Informe o serviço',
        content: (
            <span>
                Você começa nos informando qual o <b>serviço</b> que você deseja. Caso queira, você pode adicionar <b>serviços adicionais</b>.
            </span>
        ),
        media: 'https://facilita-app.s3.sa-east-1.amazonaws.com/InitialInfo.gif',
    },
    {
        title: 'Número do Processo',
        content: (
            <span>
                Caso já exista um processo em andamento, informe corretamente o <b>número do processo</b>. Isso irá facilitar o cadastro de petições futuras para o mesmo caso.
            </span>
        ),
        media: 'https://facilita-app.s3.sa-east-1.amazonaws.com/ProcessNumber.gif',
    },
    {
        title: 'Informações do caso',
        content: 'Aqui você deve nos contar as informações relevantes ao caso em questão. Lembre-se que quanto mais detalhes você fornecer, melhor será o resultado final do serviço.',
        media: 'https://facilita-app.s3.sa-east-1.amazonaws.com/MainInfo.gif',
    },
    {
        title: 'Anexo do processo',
        content: 'Caso o processo já esteja tramitando, inclua o anexo do processo para que possamos analisar e trazer o melhor serviço para o seu caso',
        media: 'https://facilita-app.s3.sa-east-1.amazonaws.com/ProcessDocument.gif',
    },
    {
        title: 'Documentos',
        content: 'Além disso, inclua todos os documentos que você ache relevante para o desenvolvimento do seu pedido',
        media: 'https://facilita-app.s3.sa-east-1.amazonaws.com/Documents.gif',
    },
    {
        title: 'Conclusão',
        content: 'Pronto! Sua solicitação está cadastrada e sendo desenvolvida a todo vapor! Você pode ver a data de entrega e quais foram os serviços debitados pela solicitação ao finalizar o cadastro.',
        media: 'https://facilita-app.s3.sa-east-1.amazonaws.com/Conclusion.gif',
    },
]