import { useState, useEffect } from 'react';

import { Button } from '@material-ui/core';

import NewRequestModal from './new.request.modal.component';

import urlQueryProvider from '../../providers/url.query.provider';
import announcementService from '../../domain/services/marketing/announcement.service';
import { announcements } from '../../domain/constant/announcements.constant';
import Tutorial from '../shared/Tutorial';
import { newRequestTutorialSteps } from '../../domain/constant/tutorials/tutorials.constant';

const NewRequestButton = ({
    customLabel = '',
    setAlert,
    CustomButton = null,
    initialValue = null,
    setIsNewRequestModalOpen = isOpen => {},
    urlTrigger = true,
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);

    const currentAnchor = urlQueryProvider.getAnchor();

    useEffect(() => {
        if (currentAnchor == 'new' && urlTrigger)
            handleNewRequestModal();
    }, [currentAnchor]);

    const checkIfCanShowTutorial = () => {
        const customerAlreadySawAnnouncement = announcementService.isShowed(announcements.tutorialNewRequest);
        if (customerAlreadySawAnnouncement) return;
        setShowTutorial(true);
    };

    const markTutorialViewed = () => {
        announcementService.markViewed(announcements.tutorialNewRequest)
            .then(() => setShowTutorial(false));
    }

    const handleNewRequestModal = () => {
        setIsModalOpen((openModal) => !openModal);
        setIsNewRequestModalOpen(!isModalOpen);

        if (isModalOpen) return;
        checkIfCanShowTutorial();
    }

    const openNewRequest = () => {
        setIsModalOpen(true);
        checkIfCanShowTutorial();
    }

    return (
        <>
            {CustomButton ? (
                <CustomButton onClick={openNewRequest} />
            ) : (
                <Button
                    type='button' mr={2} variant='contained' color='primary'
                    aria-controls='newRequestModal' aria-haspopup='true'
                    style={{ height: 36, marginLeft: 10 }}
                    onClick={openNewRequest}
                    id='new-solicitation'
                >
                    {customLabel || 'Nova Solicitação'}
                </Button >
            )}

            <NewRequestModal
                isOpen={isModalOpen && !showTutorial}
                onClose={handleNewRequestModal}
                setAlert={setAlert}
                initialValue={initialValue}
                openTutorial={() => setShowTutorial(true)}
            />

            <Tutorial
                title="Como cadastrar sua solicitação"
                open={showTutorial}
                onClose={markTutorialViewed}
                onFinish={markTutorialViewed}
                steps={newRequestTutorialSteps}
            />
        </>
    );
}

export default NewRequestButton;