import { useState, useEffect } from 'react';

import swal from 'sweetalert';
import { animateScroll as scroll } from "react-scroll";

import { Box, Grid, TextField, Fab, Tooltip, } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import Chat from '../Chat';

import commentsService from '../../domain/services/solicitation/comments.service';
import loginService from '../../domain/services/login.service';
import { events } from '../../domain/services/solicitation/comments.service';
import solicitationService from '../../domain/services/solicitation/solicitation.service';

import { stepsSolicitation } from "../../domain/constant/steps.solicitation.constant";

function CommentsForm({ request, resolvePending }) {

    const [comments, setComments] = useState([]);

    const [showLastCommentCustomerRelasePopover, setShowLastCommentCustomerRelasePopover] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        if (!request.id)
            return;

        findInitialData();

        events.on('onRefresh', findComments);
        return () => events.removeListener('onRefresh', findComments);

    }, [request.id]);

    const findInitialData = async () =>
        findComments()
            .then(scroll.scrollToBottom);

    const findComments = async () => {

        setIsLoading(true);

        await commentsService
            .find(request.id)
            .then((res) => setComments(res.data || []))
            .finally(() => setIsLoading(false));
    }

    const handleSave = () =>
        saveComments({
            description: document.getElementById('commentText').value,
            requestId: request.id,
        })
            .then(() => {
                clearComment();
                loginService.isFaciliter() && setShowLastCommentCustomerRelasePopover(true);
            });

    const saveComments = async (item) => {

        await commentsService.save(item).then(() => findComments(item.requestId));

        if (request.status != stepsSolicitation.PENDING || !loginService.isCustomer())
            return;

        checkIsPending();
    };

    const checkCanComment = () => solicitationService.canAddContent(request) || isLoading;

    const checkIsPending = () => {
        !request.isFinancialPending && swal({
            title: 'Resolver Pendências',
            text: `
                Precisamos da sua colaboração para resolver pendências nesta solicitação. 
                
                Por favor, verifique se você já anexou os comentários necessários e/ou inseriu as informações solicitadas pelo analista. Sua pronta resposta é fundamental para prosseguirmos. 
                
                Você já tratou todas as pendências existentes? 
                
                Agradecemos sua ajuda!
            `,
            icon: 'info',
            buttons: {
                confirm: { text: 'Sim', className: 'MuiButton-containedPrimary' },
                cancel: 'Não'
            },
        }).then((res) => res && resolvePending());
    };

    const clearComment = () => {
        document.getElementById('commentText').value = '';
    };

    return (
        <Grid container direction='column'>

            <Grid style={{ marginBottom: 40 }}>
                <Chat
                    messages={comments} onRefresh={findComments} isLoading={isLoading}
                    showLastCommentCustomerRelasePopover={showLastCommentCustomerRelasePopover}
                    setShowLastCommentCustomerRelasePopover={setShowLastCommentCustomerRelasePopover}
                />
            </Grid>

            <Grid container xs={12}>

                <Grid item xs>
                    <TextField
                        id="commentText"
                        label="Escreva seu comentário"
                        multiline={true}
                        variant="outlined"
                        fullWidth
                        disabled={!checkCanComment()}
                    />
                </Grid>

                <Grid item>
                    <Box ml={2}>
                        <Tooltip title="Enviar">
                            <Fab
                                color="primary"
                                size="medium"
                                onClick={handleSave}
                                disabled={!checkCanComment()}
                            >
                                <Send />
                            </Fab>
                        </Tooltip>
                    </Box>
                </Grid>

            </Grid>

        </Grid >
    );
}

export default CommentsForm;
