import api from './api';

const find = async (id) =>
    api.get({ type: 'user', service: `users/${id}` });

const findAll = async () =>
    api.get({ type: 'user', service: `users/` });

const findRoles = async (source) =>
    api.get({ type: 'user', service: `users/roles/source/${source}` });

const findByRoles = async (data) =>
    api.post({ type: 'user', service: 'users/roles-filter', data });

const save = async (data) =>
    api.post({ type: 'user', service: `users/`, data });

const remove = async (id) =>
    api.remove({ type: 'user', service: `users/${id}` });


export default {
    find,
    findAll,
    findRoles,
    findByRoles,
    save,
    remove
}