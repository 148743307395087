import api from './api';
import axios from 'axios';

const findStates = async () =>
    api.get({ type: 'user', service: `states/` });

const findCities = async (uf) =>
    api.get({ type: 'user', service: `states/${uf}/cities` });

const findAllAvailableStatesByArea = async (areaId) =>
    api.get({ type: 'user', service: `states/available-states/${areaId}` });

const getCityByGeoLocation = async () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
        async position => {
            try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}`);
            resolve(response.data.address);
            } catch (error) {
            reject('Erro ao buscar a cidade.');
            }
        },
        error => {
            reject(error.message);
        }
        );
    });
    };
      

export default {
    findStates,
    findCities,
    getCityByGeoLocation,
    findAllAvailableStatesByArea
}