import { get, post, put, remove } from '../api';

import moment from 'moment';

import loginService from '../login.service';
import requestStatusService from './request.status.service';
import requestValidator from '../../validators/request.validator';

import repository from '../../repositories/request/request.repository';

import { stepsSolicitation } from "../../constant/steps.solicitation.constant";

const find = async (id) =>
    get({ type: 'user', service: `requests/${id}` });

const findBasicById = async (id) =>
    get({ type: 'user', service: `requests/${id}/basic` });

const findPreview = async (id) =>
    get({ type: 'user', service: `requests/${id}/preview` });

const findRequestAreas = async (id) =>
    get({ type: 'user', service: `requests/${id}/areas` });

const findFaciliterReviewerSolicitations = async (faciliterId, { pageIndex, pageSize }, filters) =>
    post({ type: 'user', service: `requests/faciliter-reviewer/${faciliterId}?page=${pageIndex}&perPage=${pageSize}`, data: filters })

const findFinancialPendingByCustomer = async (customerId) =>
    get({ type: 'user', service: `requests/customers/${customerId}/financial-pending` })

const findToLink = async (faciliterId) =>
    get({ type: 'user', service: `requests/link-faciliter/${faciliterId}` })

const findFaciliterPrice = async (requestId, faciliterId) =>
    get({ type: 'user', service: `requests/faciliter-link-price/${faciliterId}/request/${requestId}` })

const findAllAreas = async () =>
    repository
        .findAllAreas()
        .then(res => res.data || []);

const findAreasByModalityId = async (modalityId) =>
    repository.findAreasByModalityId(modalityId).then(res => res.data);

const getModalities = async () =>
    get({ type: 'user', service: `requests/modalities` })

const getChecklist = async (requestId) =>
    get({ type: 'user', service: `requests/${requestId}/checklist` })

const getAvailableDueDate = async (requestId) =>
    get({ type: 'user', service: `requests/deadline/${requestId}/all-services` })

const calculateAllServicesDueDate = async (mainId) =>
    repository.calculateAllServicesDueDate(mainId)
        .then(res => res.data);

const getPartTypes = async (modalityId) =>
    get({ type: 'user', service: `requests/part-types/${modalityId}` })

const getAvailableOperationalDueDate = async (requestId) =>
    get({ type: 'user', service: `requests/deadline/${requestId}/available-operational` })

const getSkeletons = async (area, modality) =>
    get({ type: 'user', service: `requests/skeletons/area/${area}/modality/${modality}` })

const getCustomerActionTypes = async (customerId) =>
    get({ type: 'user', service: `requests/customers/${customerId}/action-types` })

const getCustomerDistricts = async (customerId) =>
    get({ type: 'user', service: `requests/customers/${customerId}/districts` })

const save = async (data) =>
    post({
        type: 'user', service: 'requests', data: {
            ...data,
            dueDate: moment(data.dueDate).format("YYYY-MM-DDTHH:mm"),
            operationalDueDate: data.operationalDueDate && moment(data.operationalDueDate).format("YYYY-MM-DDTHH:mm"),
            servicePublishedAt: data.servicePublishedAt && moment(data.servicePublishedAt).format("YYYY-MM-DDTHH:mm")
        }
    });

const confirmAnalysisValidation = async (id, data) =>
    post({ type: 'user', service: `requests/${id}/confirm-analysis-validation`, data });

const faciliterDropout = async (requestId, description) =>
    post({ type: 'user', service: `requests/${requestId}/members/faciliter-dropout`, data: { description } });

const modifyRequestServices = async (data) =>
    post({ type: 'user', service: `/requests/modify-request-services`, data });

const faciliterCorrectionDropout = async (requestId, correspondentId) =>
    repository.faciliterCorrectionDropout(requestId, correspondentId).then(res => res.data);

const editValue = async (id, value) =>
    put({ type: 'user', service: `requests/${id}`, data: { ...value } })

const editAllServicesDueDate = async (id, value) =>
    put({ type: 'user', service: `requests/deadline/${id}/all-services-due-date`, data: { ...value } })

const editChecklist = async (requestId, checklist) =>
    put({ type: 'user', service: `requests/${requestId}/update-checklist`, data: checklist })

const checkDebit = async (customerId) =>
    put({ type: 'user', service: `requests/check-debit/${customerId}` })

const releaseFinancialPending = async (requestId) =>
    put({ type: 'user', service: `requests/${requestId}/release-financial-pending` })

const updateCustomerPrice = async (mainId, serviceId, price, billingId) =>
    put({ type: 'user', service: `/requests/${mainId}/customer-price`, data: { price, serviceId, billingId } });

const newValue = async () => {

    const user = loginService.getUserAuthentication();

    return {
        id: null,
        customerId: user.customer?.id,
        type: "petition",
        customerHasRegisteredRequest: user.customer?.hasRegisteredRequest
    }
}

const getExternalLinkState = () =>
    localStorage.getItem('@facilita-app/external-link-request');

const setExternalLinkToState = (requestId, correspondentId) =>
    localStorage.setItem('@facilita-app/external-link-request', `/external-link/solicitations/${requestId}/faciliter/${correspondentId}`);

const removeExternalLinkState = () =>
    localStorage.removeItem('@facilita-app/external-link-request');

const mapValidFields = ({ value, field }) => ({
    customerId: value,
    serviceId: value,
    customerPrice: parseFloat(value),
    faciliterServicePrice: parseFloat(value),
    dueDate: value ? moment(value).format("YYYY-MM-DDTHH:mm") : null,
    description: value,
    parts: value,
    processNumber: value,
    serviceAdditionalInfo: JSON.stringify(value),
    operationalDueDate: value ? moment(value).format("YYYY-MM-DDTHH:mm") : null,
    status: value,
    type: value,
    internalAnalysis: value,
    additionalInformation: value,
    isFinancialPending: value,
    representedParts: value,
    typeAction: value,
    highlightsPoints: value,
    subscriber: value,
    areaId: value,
    modality: value,
    servicePublishedAt: value ? moment(value).format("YYYY-MM-DDTHH:mm") : null
}[field]);

const mapSolicitationCustomerToExcel = (solicitations) => {
    return solicitations.map((solicitation) => ({
        "ID": solicitation.id,
        "Status": requestStatusService.getStepByKey(solicitation.status).value,
        "Data de Criação": solicitation.created ? convertOnlyDate(solicitation.created) : null,
        "Hora de Criação": solicitation.created ? convertOnlyHour(solicitation.created) : null,
        "Cliente": solicitation.customer,
        "ID Do Cliente": solicitation.customerId,
        "Tipo": (solicitation.type == 'diligence') ? 'Diligência' : 'Petição',
        "Serviço": solicitation.serviceName,
        "Área": solicitation.area,
        "Modalidade": solicitation.modality,
        "N° do Processo": solicitation.processNumber,
        "Partes": solicitation.parts,
        "Data de Conclusão": solicitation.concludedAt ? convertOnlyDate(solicitation.concludedAt) : null,
        "Hora de Conclusão": solicitation.concludedAt ? convertOnlyHour(solicitation.concludedAt) : null,
        "Data 1° conclusão": solicitation.firstConcluded ? convertOnlyDate(solicitation.firstConcluded) : null,
        "Hora 1° conclusão": solicitation.firstConcluded ? convertOnlyHour(solicitation.firstConcluded) : null,
        "Preço do Cliente": loginService.isValidRoles(['admin'], loginService.getUserAuthentication()) ? parseFloat(solicitation.customerPrice || 0) : null,
        "Preço do Faciliter": loginService.isValidRoles(['admin'], loginService.getUserAuthentication()) ? parseFloat(solicitation.faciliterServicePrice || 0) : null,
        "Data de Entrega": solicitation.dueDate ? convertOnlyDate(solicitation.dueDate) : null,
        "Hora de Entrega": solicitation.dueDate ? convertOnlyHour(solicitation.dueDate) : null,
        "Data de Entrega Operacional": solicitation.operationalDueDate ? convertOnlyDate(solicitation.operationalDueDate) : null,
        "Hora de Entrega Operacional": solicitation.operationalDueDate ? convertOnlyHour(solicitation.operationalDueDate) : null,
        "Faciliters": (solicitation.members || []).length ? solicitation.members.map(item => item.name).join(", ") : null,
        "Revisores": (solicitation.reviewers || []).length ? solicitation.reviewers.map(item => item.name).join(", ") : null,
        "Analistas": (solicitation.analysts || []).length ? solicitation.analysts.map(item => item.name).join(", ") : null
    }))
}

const mapSolicitationMemberToExcel = (solicitations) => {
    return solicitations.map((solicitation) => ({
        "ID da Solicitação": solicitation.requestId,
        "Tipo": (solicitation.serviceName == 'Petição') ? 'Petição' : 'Diligência',
        "Serviço": solicitation.serviceName,
        "Área": solicitation.area,
        "Modalidade": solicitation.modality,
        "Nome": solicitation.name,
        "Email": solicitation.email,
        "CPF": solicitation.cpf,
        "Faciliter": solicitation.isFaciliter ? 'SIM' : 'NÃO',
        "N° do Processo": solicitation.processNumber,
        "Status": requestStatusService.getStepByKey(solicitation.status).value,
        "Data da criação": solicitation.created ? convertOnlyDate(solicitation.created) : null,
        "Hora da criação": solicitation.created ? convertOnlyHour(solicitation.created) : null,
        "Data 1° conclusão": solicitation.firstConcluded ? convertOnlyDate(solicitation.firstConcluded) : null,
        "Hora 1° conclusão": solicitation.firstConcluded ? convertOnlyHour(solicitation.firstConcluded) : null,
        "Data 1° conclusão Indicação de Petição": solicitation.firstConcludedAnalyze ? convertOnlyDate(solicitation.firstConcludedAnalyze) : null,
        "Data 1° conclusão": solicitation.firstConcluded ? convertOnlyDate(solicitation.firstConcluded) : null,
        "Data Hora 1° conclusão": solicitation.firstConcluded ? convertOnlyHour(solicitation.firstConcluded) : null,
        "Data da conclusão": solicitation.concludedAt ? convertOnlyDate(solicitation.concludedAt) : null,
        "Hora da conclusão": solicitation.concludedAt ? convertOnlyHour(solicitation.concludedAt) : null,
        "Preço": parseFloat(solicitation.price || 0),
        "Dados Bancários": getBankData(solicitation.bankData),
        "Razão Inativação": solicitation.reason,
        "ID da Fatura do Faciliter": solicitation.invoiceId,
        "Fatura Aberta Em": solicitation.invoiceCreated ? moment.utc(solicitation.invoiceCreated).format('DD/MM/YYYY') : '',
        "Fatura Fechada Em": solicitation.invoiceClosed ? moment.utc(solicitation.invoiceClosed).format('DD/MM/YYYY') : '',
        "Preço a Pagar": solicitation.invoicePriceToPay,
    }))
}

const convertOnlyHour = (timestamp) => moment.utc(timestamp).format('HH:mm');

const convertOnlyDate = (timestamp) => moment.utc(timestamp).format('DD/MM/YYYY');

const getTitle = (solicitation) => {

    const getTitleActionByType = {
        'petition': () => {

            let title = 'Documento Jurídico';

            if (solicitation.area)
                title += ' - ' + solicitation.area;

            if (solicitation.modality)
                title += ' - ' + solicitation.modality;

            return title;
        },
        'hearing': () => {

            const finalServiceKey = solicitation.forwardToHearing ? 'hearing' : solicitation.forwardToHearingInPerson ? 'hearingInPerson' : 'hearingGenericLabel';

            const labelByFinalService = {
                hearing: 'Audiência Virtual',
                hearingInPerson: 'Audiência Presencial',
                hearingGenericLabel: 'Audiência'
            }

            const serviceLabel = labelByFinalService[finalServiceKey]
            const areaLabel = solicitation.area ? ' - ' + solicitation.area : '';

            return serviceLabel + areaLabel;
        }
    }

    return getTitleActionByType[solicitation.type || 'petition']();
}

const getArea = (serviceAdditionalInfo) => {

    if (!serviceAdditionalInfo)
        return null;

    let area = serviceAdditionalInfo.find((item) => item.label == "Área");
    return area ? area.value : null;
}

const getModalidade = (serviceAdditionalInfo) => {

    if (!serviceAdditionalInfo)
        return null;

    let modalidade = serviceAdditionalInfo.find((item) => item.label == "Modalidade");
    return modalidade ? modalidade.value : null;
}

const getBankData = (bankData) =>
    `Conta:  ${bankData.account} - Agência: ${bankData.agency} - Banco: ${bankData.bank} - Pix: ${bankData.pix}`;

const isAnalysisValidation = (analysisInfo) => (
    analysisInfo?.requestValidationAt && !analysisInfo?.validatedAt && !analysisInfo?.expiredAt
)

const getExpandendSteps = (expanded) => {
    return expanded.reduce((expandeds, item, index) => {
        if (item)
            expandeds.push(index);

        return expandeds;
    }, []);
}

const getPaginationBySteps = (pagination) =>
    Object.keys(pagination).reduce((pageByStep, key, index) => {

        if (key !== 'total')
            pageByStep[key] = {
                page: pagination[key].page,
                rowsPerPage: pagination[key].rowsPerPage,
            };

        return pageByStep;
    }, {});


const getListScroller = () => {
    let listScroller = localStorage.getItem('@facilita-app/solicitation-list-scroller');
    listScroller = listScroller ? JSON.parse(listScroller) : {};
    return listScroller;
}

const setListScroller = (stepValue, expanded, pagination) => {
    localStorage.setItem('@facilita-app/solicitation-list-scroller',
        JSON.stringify({
            stepValues: getExpandendSteps(expanded),
            stepValue,
            scroll: document.documentElement.scrollTop,
            pagination: getPaginationBySteps(pagination),
        }));
}

const checkIfStepBeforeReview = (requestStatus) =>
    stepsSolicitation.stepTypes[requestStatus]?.position < stepsSolicitation.stepTypes.REVIEW.position;

const canAddContent = (request) =>
    !loginService.isCustomer() ||
    request.status == stepsSolicitation.CORRECTION ||
    checkIfStepBeforeReview(request.status);

const canUpload = (request) =>
    !loginService.isCustomer() ||
    request.status == stepsSolicitation.CORRECTION ||
    checkIfStepBeforeReview(request.status);

const canShowDevelopButton = (request) => {

    const canShowButton = {
        'Petição': true,
        'Petição sem Indicação de Petição': true,
        'Indicação de Petição + Petição': false,
        'Somente Indicação de Petição': false
    }

    return (
        request.status === stepsSolicitation.NOT_STARTED ||
        request.status === stepsSolicitation.IN_PROGRESS
    ) && canShowButton[request.faciliterServiceName];
}

const canDevelopSolicitation = () => loginService.isFaciliter() || loginService.isInternal()

const canReviewSolicitation = () => loginService.isInternal() || loginService.isFaciliterReviewer()

const canCorrectSolicitation = (request) =>
    loginService.isInternal() &&
    request.status === stepsSolicitation.CORRECTION &&
    !requestValidator.isAnalysisModality(request)

const canViewCorrections = () =>
    !loginService.isFaciliter() || loginService.isFaciliterReviewer()

const editModality = async (requestId, requestChanges, fieldsChanges) =>
    repository.editModality(requestId, requestChanges, fieldsChanges).then(res => res.data);

const findServiceFields = async (mainId) =>
    repository.findServiceFields(mainId)
        .then(res => res.data);

const findModalitiesByServiceId = async (faciliterServiceId) =>
    repository.findModalitiesByServiceId(faciliterServiceId)
        .then(res => res.data);

const getHearingDateTimeColor = (request) => {
    const hearingDate = new Date(request.hearingDateTime);
    const now = new Date();
    const hoursUntilHearingDate = hearingDate - now;
    const hoursToConsiderHearingEmergency = 11 * 60 * 60 * 1000;
    const isInEmergencyRange = hoursUntilHearingDate < hoursToConsiderHearingEmergency
    const notInRightProductionFlowStatus = [stepsSolicitation.LINK, stepsSolicitation.PENDING, stepsSolicitation.NOT_STARTED, stepsSolicitation.ANALYSE]
    const isNotInRightProductionFlowStatus = notInRightProductionFlowStatus.includes(request.status)
    return isInEmergencyRange && isNotInRightProductionFlowStatus ? "#FF6500" : "#376FD0";
};

const isExpressService = (request) =>
    request.isExpressService && request.faciliterServiceKey != "refinePetitionIA"

const isPremiumService = (request) =>{

    const isPremium = (!!request.hasActivePremiumService || !!request.linkPremiumFaciliter)

    if (loginService.isFaciliter() && isPremium && request.faciliterServiceKey == "refinePetitionIA")
        return false

    return isPremium

}
export default {
    find,
    findBasicById,
    findPreview,
    findRequestAreas,
    findFinancialPendingByCustomer,
    findToLink,
    findServiceFields,
    getAvailableDueDate,
    calculateAllServicesDueDate,
    findAllAreas,
    getModalities,
    getSkeletons,
    newValue,
    save,
    confirmAnalysisValidation,
    editValue,
    editAllServicesDueDate,
    mapValidFields,
    checkDebit,
    releaseFinancialPending,
    getExternalLinkState,
    setExternalLinkToState,
    removeExternalLinkState,
    getAvailableOperationalDueDate,
    mapSolicitationCustomerToExcel,
    mapSolicitationMemberToExcel,
    getTitle,
    getArea,
    findAreasByModalityId,
    getModalidade,
    getPartTypes,
    isAnalysisValidation,
    getListScroller,
    setListScroller,
    getPaginationBySteps,
    editChecklist,
    getChecklist,
    faciliterDropout,
    faciliterCorrectionDropout,
    findFaciliterReviewerSolicitations,
    updateCustomerPrice,
    canAddContent,
    canUpload,
    canShowDevelopButton,
    canDevelopSolicitation,
    canReviewSolicitation,
    canCorrectSolicitation,
    canViewCorrections,
    editModality,
    findModalitiesByServiceId,
    getHearingDateTimeColor,
    getCustomerActionTypes,
    getCustomerDistricts,
    findFaciliterPrice,
    modifyRequestServices,
    isExpressService,
    isPremiumService
}