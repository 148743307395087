import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";
import Footer from "../components/Footer";

import { spacing } from "@material-ui/system";
import {
    Hidden,
    CssBaseline,
    Paper as MuiPaper,
    withWidth,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import { WhatsApp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import loginService from "../domain/services/login.service";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .MuiAccordion-root:before {
    height: 0 !important;
  }

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: background: #bdbdbd #f7f9fc;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }
  
  *::-webkit-scrollbar-track {
    background: #f7f9fc;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 5px;
    border: 3px solid #f7f9fc;
  }
  .swal2-container {
    z-index: 2147483647 !important;
  };
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: #F7F9FC !important;
  margin-top: 50px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

const PageLayout = ({ children, routes, width, hideSideBar = false }) => {

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const renderWhatsAppButton = () => {

        if (loginService.isInternal() || !loginService.isLogin()) return <></>;

        return (
            <Link to={{ pathname: 'https://wa.me/5515998526603' }} target="_blank">
                <div className="fixed h-12 w-12 lg:h-14 lg:w-14 right-8 bottom-8 rounded-full bg-[#25d366] flex justify-center items-center hover:brightness-105 cursor-pointer transition-[filter_.3s_ease] z-[100000]"
                    style={{ boxShadow: '0 0 8px #0002' }}
                >
                    <WhatsApp
                        style={{
                            color: '#fff',
                            transform: 'translate(1px, -1px)',
                            fontSize: 34,

                        }}
                    />
                </div>
            </Link>
        );
    };

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            {!hideSideBar && (
                <Drawer>
                    <Hidden mdUp implementation="js">
                        <Sidebar
                            routes={routes}
                            PaperProps={{ style: { width: drawerWidth } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Sidebar
                            routes={routes}
                            PaperProps={{ style: { width: drawerWidth } }}
                        />
                    </Hidden>
                </Drawer>
            )}
            <AppContent>
                <Header onDrawerToggle={handleDrawerToggle} showAdvertising={true} hideSideBar={hideSideBar} />
                <MainContent p={isWidthUp("lg", width) ? 12 : 5}>
                    {children}
                    {renderWhatsAppButton()}
                </MainContent>
                <Footer />
            </AppContent>
        </Root>
    );
};

export default withWidth()(PageLayout);
