import Async from "../components/Async";

import { EventNote } from "@material-ui/icons";

const AuthGuard = Async(() => import("./auth.guard"));

const VoiceCall = Async(() => import('../pages/crm/crm.voice.call'));
const Agenda = Async(() => import('../pages/crm/crm.agenda'));
const OpportunityCenter = Async(() => import('../pages/crm/crm.opportunity.center'));
const OpportunityDetails = Async(() => import('../pages/crm/crm.opportunity.details'));
const Opportunities = Async(() => import('../pages/crm/crm.opportunities'));

const routes = {
    crm: {
        id: 'Gestto',
        path: '/crm',
        icon: <EventNote />,
        component: null,
        guard: AuthGuard,
        roles: ['seller'],
        children: [
            {
                id: 'crmAgenda',
                name: 'Agenda do dia',
                path: '/crm/agenda',
                component: Agenda,
            },
            {
                key: 'crmOpportunityCenter',
                name: 'Central de oportunidades',
                path: '/crm/opportunity-center',
                component: OpportunityCenter,
            }
        ]
    },
    opportunityDetails: {
        id: 'opportunityDetails',
        path: '/crm/opportunity/:id?',
        component: OpportunityDetails,
        guard: AuthGuard,
        roles: ['seller'],
    },
    opportunities: {
        id: 'opportunities',
        path: '/crm/opportunities',
        component: Opportunities,
        guard: AuthGuard,
        roles: ['seller'],
    },
}

const externalRoutes = {
    voiceCall: {
        id: 'voiceCall',
        path: '/crm/voice-call/:contactId?',
        component: VoiceCall,
        guard: AuthGuard,
        roles: ['seller'],
    },
}

const pages = Object.values(routes);

const externalPages = Object.values(externalRoutes);

export default {
    routes,
    externalRoutes,
    pages,
    externalPages
}