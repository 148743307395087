import announcementRepository from "../../repositories/marketing/announcement.repository";
import loginService from "../login.service";

const isShowed = (announcementKey) => {
    const user = loginService.getUserAuthentication() || {};

    const isViewed = user.announcementsViewed?.includes(announcementKey);
    const isInactive = user.announcementsInactive?.includes(announcementKey);

    return isViewed || isInactive;
}

const updateLocalStorage = (announcementKey) => {

    const user = loginService.getUserAuthentication();

    const newAnnouncementsViewed = (user.announcementsViewed || []);
    newAnnouncementsViewed.push(announcementKey)

    const newUser = { ...user, announcementsViewed: newAnnouncementsViewed }
    loginService.refreshUserAuthentication(newUser);
}

const markViewedByKey = async (announcementKey, additionalInfo) =>
    announcementRepository.markViewedByKey(announcementKey, additionalInfo);

const markViewed = async (announcementKey, additionalInfo) =>
    markViewedByKey(announcementKey, additionalInfo)
        .then(() => updateLocalStorage(announcementKey));

const getAnnouncementAllowedDisplay = async (announcementKey) => {

    const user = loginService.getUserAuthentication() || {};

    const customerId = user?.customer?.id;

    if (!customerId)
        return { success: false };

    return announcementRepository.getAnnouncementAllowedDisplay(announcementKey, customerId)
       .then(({ data }) => data)
}

export default {
    isShowed,
    updateLocalStorage,
    markViewedByKey,
    markViewed,
    getAnnouncementAllowedDisplay,
}