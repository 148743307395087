const cnpj = (value) => {

    if (!value)
        return value;

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length <= 2)
        return onlyNums;

    if (onlyNums.length <= 5)
        return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;

    if (onlyNums.length <= 8)
        return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}`;

    if (onlyNums.length <= 12)
        return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;

    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
}

const cpf = (value) => {

    if (!value)
        return value;

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length <= 3)
        return onlyNums;

    if (onlyNums.length <= 6)
        return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;

    if (onlyNums.length <= 9)
        return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`;

    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
}

const cellPhone = value => {

    if (!value)
        return value;

    let onlyNums = value.replace(/[^\d]/g, "");

    const isDdiIncluded = onlyNums.slice(0, 2) == 55 && onlyNums.length > 11;

    if (isDdiIncluded)
        onlyNums = onlyNums.substring(2);

    if (onlyNums.length <= 2) {
        return onlyNums;
    }

    if (onlyNums.length <= 7) {
        return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
    }

    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
};

const name = value => {

    if (!value)
        return value;

    return value.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

const zipCode = (value) => {

    if (!value)
        return value;

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length <= 5)
        return onlyNums;

    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
}

const cardNumber = (value) => {

    if (!value)
        return value;

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length <= 4)
        return onlyNums;

    if (onlyNums.length <= 8)
        return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)}`;

    if (onlyNums.length <= 12)
        return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(8, 12)}`;

    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(8, 12)} ${onlyNums.slice(12, 16)}`;
}

const expirationDate = (value) => {

    if (!value)
        return value;

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length <= 2)
        return onlyNums;

    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
}

const cvvNumber = (value) => {

    if (!value)
        return value;

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.length <= 3)
        return onlyNums;

    return `${onlyNums.slice(0, 4)}`;
}

const camelToSnakeCase = (str) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const formatCurrency = (value) => {
    let newValue = value.replace(/\D/g, '');
    newValue = newValue.replace(/^0+/, '');

    if (newValue.length == 0)
        return '';

    let cents = newValue.slice(-2);
    let integerPart = newValue.slice(0, -2);

    if (integerPart === '') {
        integerPart = '0';
        if (cents.length === 1)
            cents = '0' + cents;
    }

    let integerPartFormatada = '';
    for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
        integerPartFormatada = integerPart[i] + integerPartFormatada;
        if (count % 3 === 2 && i > 0) {
            integerPartFormatada = '.' + integerPartFormatada;
        }
    }

    return integerPartFormatada + ',' + cents;
}

export default {
    cnpj,
    cpf,
    cellPhone,
    name,
    zipCode,
    expirationDate,
    cardNumber,
    cvvNumber,
    camelToSnakeCase,
    formatCurrency
};
