import api from '../../services/api';

const removeFine = async (requestId, responsibleId) =>
    api.post({ type: 'user', service: `requests/${requestId}/members/${responsibleId}/remove-fine` });

const removeRatingDiscount = async (requestId, responsibleId) =>
    api.post({ type: 'user', service: `requests/${requestId}/members/${responsibleId}/remove-rating-discount` });

const update = async (member) =>
    api.put({ type: 'user', service: `requests/${member.requestId}/members/${member.id}`, data: member });

export default {
    removeFine,
    removeRatingDiscount,
    update
}