import { useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";

import { Button, CircularProgress } from "@material-ui/core";

import CustomerReturnSolicitation from "../solicitations/customer.return.solicitation.component";

import stepsSolicitation from "../../domain/constant/steps.solicitation.constant";

import requestStatusService from "../../domain/services/solicitation/request.status.service";

function RequestCustomerCorrectionButton({ request, isList = false, onSendToCorrection, setAlert }) {

    const [isCorrectionFormOpen, setIsCorrectionFormOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const { promiseInProgress } = usePromiseTracker();

    const sendToCorrectionStep = async () => {

        setIsLoading(true);

        await requestStatusService
            .changeStep({ stepName: stepsSolicitation.CORRECTION, requestId: request.id })
            .then(() => setAlert("Salvo com sucesso", "success"))
            .then(onSendToCorrection)
            .catch(err => setAlert(err.response.data.message, "error"))
            .finally(() => setIsLoading(false));
    }


    const getButton = (isList) => ({
        true: (
            <Button
                variant='text'
                color='primary'
                className="w-full"
                onClick={() => setIsCorrectionFormOpen(true)}
            >
                <span className='text-sm'>Enviar para correção</span>
            </Button>
        ),
        false: (
            <Button
                disabled={isLoading || promiseInProgress}
                onClick={() => setIsCorrectionFormOpen(true)} fullWidth
                style={{
                    fontWeight: 600,
                    color: 'white', backgroundColor: 'orange',
                    opacity: isLoading || promiseInProgress ? 0.5 : 1
                }}
            >
                {isLoading && (
                    <CircularProgress style={{
                        color: "aliceblue", width: 14,
                        height: 14, marginRight: 8
                    }} />
                )}

                Solicitar correção
            </Button>
        )
    })[isList];

    return (
        <>
            {getButton(isList)}

            {isCorrectionFormOpen &&
                <CustomerReturnSolicitation
                    request={request}
                    mainRequestId={request?.mainId || request?.id}
                    open={isCorrectionFormOpen}
                    setOpen={setIsCorrectionFormOpen}
                    returnStep={sendToCorrectionStep}
                    setAlert={setAlert}
                    isCreating
                />
            }
        </>
    );
}

export default RequestCustomerCorrectionButton;
