
import { Grid } from '@material-ui/core';

import UnrevokeButton from './unrevoke.button.component';
import CustomerRevokeButton from './customer.revoke.button.component';
import RequestCustomerCorrectionButton from './request.customer.correction.button.component';
import stepsSolicitation from '../../domain/constant/steps.solicitation.constant';

import loginService from '../../domain/services/login.service';
import { useTour } from '../../context/tourContext';
import { tours } from '../../domain/constant/tour.constant';

export default function RequestDetailsActionButtons({
    mainRequestId, request, setAlert, refresh, isSmall
}) {

    const { registerRef } = useTour();

    const correctionRef = registerRef(tours.concludedRequestTour, 'correction');

    const getCustomerRevokeButton = () => {

        if (!loginService.isCustomer())
            return null;

        return (
            <Grid item xs={isSmall ? true : 12}>
                <CustomerRevokeButton mainId={mainRequestId}
                    onFinish={refresh} setAlert={setAlert} />
            </Grid>
        )
    }

    const getUnrevokeButton = () => {

        const showButton = (
            request.status == stepsSolicitation.REVOKED
            && (loginService.isCustomer() || loginService.isInternal())
        )

        if (!showButton)
            return null;

        return (
            <Grid item xs={isSmall ? true : 12}>
                <UnrevokeButton request={request}
                    refresh={refresh} setAlert={setAlert} />
            </Grid>
        )
    }

    return (
        <Grid container spacing={3} direction='column'>

            {(loginService.isCustomer() && request.status == stepsSolicitation.CONCLUDED) && (
                <Grid item xs={isSmall ? true : 12} innerRef={correctionRef}>
                    <RequestCustomerCorrectionButton
                        request={request}
                        setAlert={setAlert}
                        onSendToCorrection={refresh}
                    />
                </Grid>
            )}

            {getCustomerRevokeButton()}

            {getUnrevokeButton()}

        </Grid>
    );
}