import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { EventOutlined } from '@material-ui/icons';

import RequestServiceDocumentsContent from './request.service.documents.content.component';
import LoadingProgress from '../../LoadingProgress';

import communicationProvider from '../../../providers/communication.provider';

import requestServiceDocumentService from '../../../domain/services/request/service/request.service.document.service';
import { useTour } from '../../../context/tourContext';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 599px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    }
}));

const RequestServiceDocuments = ({ request, mainRequestId, isLoading: isPageLoading, alert }) => {

    const { registerRef } = useTour();

    const documentsRef = registerRef('concludedRequestTour', 'documents')

    const classes = useStyles();

    const [documents, setDocuments] = useState({});

    const [isDocumentsLoading, setIsDocumentsLoading] = useState(true);

    useEffect(() => findDocuments(mainRequestId), []);

    const findDocuments = async (id) =>
        requestServiceDocumentService
            .find(id)
            .then(setDocuments)
            .catch(err => communicationProvider.getErrorMessage(err, alert, 'Não foi possível encontrar documentos!'))
            .finally(() => setIsDocumentsLoading(false));

    const isLoading = () => isPageLoading || isDocumentsLoading;

    const getLastCorrectionSendDate = () => {

        if (!request.lastCorrectionSendDate)
            return null;

        return (
            <Grid item>
                <Typography
                    variant='h6' color='textSecondary'
                    style={{ display: 'inline-flex', fontSize: 12, float: 'right' }}
                >
                    <EventOutlined style={{ marginRight: 6, marginTop: -1, fontSize: 15 }} />

                    Correção solicitada em {moment(request.lastCorrectionSendDate).format("DD [de] MMMM [de] YYYY [às] HH:mm")}
                </Typography>
            </Grid>
        )
    }

    const getContent = () => {

        if (isLoading())
            return (
                <Grid style={{ width: '100%', marginTop: 10 }}>
                    <LoadingProgress message="Buscando documentos..." />
                </Grid>
            )

        const finalServiceNotConcluded = request.status != 'CONCLUDED';

        if (finalServiceNotConcluded)
            return (
                <Grid style={{ width: '75%', marginTop: 10, float: 'right' }}>
                    <Alert severity='info' color='warning'>
                        <AlertTitle>
                            <strong>Documento em correção!</strong>
                        </AlertTitle>
                        A solicitação está em correção e os documentos ficarão
                        disponíveis aqui assim que a correção for finalizada.
                    </Alert>
                </Grid>
            )

        return (
            <RequestServiceDocumentsContent documents={documents} request={request} />
        )
    }

    return (
        <React.Fragment>
            <div className={classes.container}>

                <Grid style={{ marginBottom: 10 }}>

                    <Typography variant='h6' gutterBottom>
                        <strong>Documentos Finais</strong>
                    </Typography>

                    {getLastCorrectionSendDate()}

                </Grid>

                <div style={{}} ref={documentsRef}>
                    {getContent()}
                </div>

            </div>

            <Grid item xs={12}>
                <Divider />
            </Grid>
        </React.Fragment>
    );
}

export default RequestServiceDocuments;