import moment from 'moment';

import { Grid } from '@material-ui/core';

import FormField from '../../FormField';
import { CalendarToday } from '@material-ui/icons';

export default function DatePickerField(
    { name, label, value, extraInfo, setFieldValue, fieldProps, onError }
) {

    const onChange = (name, newValue) => {

        if (!newValue)
            return;

        const formatedDate = moment(newValue).format("YYYY-MM-DD");
        setFieldValue(name, formatedDate);
    }

    return (
        <Grid item xs={12}>
            <FormField
                {...fieldProps}
                type='date'
                name={name}
                field={label}
                currentValue={value ? moment(value) : null}
                onChange={onChange}
                helperText={extraInfo}
                onError={onError}
                placeholder="Selecione a data"
                InputProps={{
                    endAdornment: <CalendarToday style={{ color: '#0007', fontSize: 20 }} />
                }}
            />
        </Grid>
    );
}
