import React, { useEffect, useState } from 'react';

import { Chip, CircularProgress, Grid } from '@material-ui/core';

import RadioButtonField from './radio.button.field';

import requestFieldsService from '../../../domain/services/request/request.fields.service';
import FormField from '../../FormField';
import { ClearOutlined } from '@material-ui/icons';

export default function MultiSelectField({
    id, name, label, required, value = [], request = {}, confirmationLabel, needConfirmation,
    extraInfo, getOptions, setFieldValue, hasCustomFieldOptions, fieldProps, onError,
    fieldServiceName,
}) {

    const [options, setOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    useEffect(() => {

        if (value && value.length) {
            const selectedValues = value.map(item => ({ ...item, id: item.value, name: item.optionName }));
            setOptions(selectedValues);
        }

        const alreadyConfirmed = !!value.length || !needConfirmation;

        if (!alreadyConfirmed)
            return;

        setIsConfirmed(true);

    }, []);

    const findOptions = async () => {

        setIsLoading(true);

        await getOptions(id, name, request.areaId)
            .then(res => setOptions([...res.data]))
            .finally(() => setIsLoading(false));
    }

    const filterOptions = (options, { inputValue }) => {

        let filtered = !inputValue ? options : options.filter(item => item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);


        if (hasCustomFieldOptions && inputValue && !options.some(option => (option.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0)))
            return [{ name: `Adicionar: "${inputValue}"`, inputValue }];

        return [...filtered];
    }

    const checkIfIsNewOption = (item) => {

        const newOption = item[Math.max(0, item.length - 1)];

        if (newOption?.inputValue)
            return { ...newOption, name: newOption.inputValue };

        return null;
    }

    const removeUnselectedOption = (selectedOptions) => {
        const selectedOption = selectedOptions[selectedOptions.length - 1];
        if (!selectedOption) return [];

        const prevSelectedOptions = selectedOptions.slice(0, -1);
        const existingIndex = prevSelectedOptions.findIndex(option => option.value === selectedOption.value);

        if (existingIndex < 0) {
            return selectedOptions;
        }

        prevSelectedOptions.splice(existingIndex, 1);
        return prevSelectedOptions;
    }

    const onChange = (e, value, selectedsOptions) => {

        const newOption = checkIfIsNewOption(selectedsOptions);

        if (newOption) {
            addFieldValue(id, selectedsOptions, { name: newOption.name });
        }
        
        const newSelectedOptions = removeUnselectedOption(selectedsOptions);

        setFieldValue(name, newSelectedOptions);
        return;
    }

    const addFieldValue = async (fieldId, selectedsOptions, newOption) => {

        const data = await requestFieldsService.createFieldOption(fieldId, newOption);

        const newSelectedsOptions = JSON.parse(JSON.stringify(selectedsOptions));

        newSelectedsOptions.pop();

        setFieldValue(name, [...newSelectedsOptions, { ...data }]);
    }

    const changeConfirmation = (n, value) => {

        setIsConfirmed(value);

        if (!!value)
            return;

        setFieldValue(name, []);
    }

    const hasConfirmation = () => {

        if (!needConfirmation)
            return null;

        return (
            <Grid item xs={12}>
                <RadioButtonField
                    label={confirmationLabel}
                    name={`has${name}`}
                    value={isConfirmed}
                    setFieldValue={changeConfirmation}
                    fieldProps={fieldProps}
                    onError={onError}
                />
            </Grid>
        )

    }

    return (
        <>

            {hasConfirmation()}

            {isConfirmed && (
                <Grid item xs={12}>
                    <FormField
                        {...fieldProps}
                        type='autocomplete'
                        placeholder={`Selecione ${fieldServiceName?.toLowerCase() || ''}`}
                        name={name}
                        field={label}
                        isLoading={isLoading}
                        currentValue={value}
                        options={options.map(item => ({ ...item, value: item.id }))}
                        required={required}
                        hasCustomFieldOptions={hasCustomFieldOptions}
                        onOpen={findOptions}
                        onChange={onChange}
                        filterOptions={filterOptions}
                        onError={onError}
                        disableClearable
                        multiple
                        helperText={extraInfo}
                        disableCloseOnSelect
                        renderTags={(value, getTagProps) => 
                            value.map((option, index) => (
                                <Chip
                                    variant="default"
                                    label={option.name}
                                    style={{ background: '#e9e9e9' }}
                                    deleteIcon={(
                                        <div className='h-full flex items-center'>
                                            <ClearOutlined fontSize='small' />
                                        </div>
                                    )}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        getCustomRenderProps={(params) => ({
                            InputProps: {
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }
                        })}
                        titleSize={15}
                        titleWeight={600}
                        spacing={10}
                    />
                </Grid>
            )}

        </>
    );
}
