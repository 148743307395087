import { useEffect, useState } from 'react';

import {
    Grid, Typography, Button, Dialog, DialogContent, Divider,
    DialogActions, Collapse, CircularProgress
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import FormField from '../../FormField';

import requestServiceDocumentService from '../../../domain/services/request/service/request.service.document.service';
import loginService from '../../../domain/services/login.service';

const RequestDocumentsServiceConfigModal = ({ document, request, handleClose, onConfirm, isEdit }) => {

    const initialState = () => {

        const initialConfig = {
            isVisibleCustomer: document.isVisibleCustomer,
            isServiceMainDocument: document.isServiceMainDocument,
            serviceId: isEdit ? document.serviceId || 0 : request.serviceId
        }

        if (isEdit) {

            const nameArray = document.name.split(".");
            nameArray.pop();

            initialConfig.name = nameArray.join(".");
        }

        return initialConfig;
    }

    const [serviceConfig, setServiceConfig] = useState(initialState());
    const [standardName, setStandardName] = useState(null);

    const [requestServices, setRequestServices] = useState([])

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => { getRequestServices(request.mainId || request.id) }, []);

    useEffect(() => { getStandardName() }, [serviceConfig, requestServices]);

    const getRequestServices = async (requestId) =>
        requestServiceDocumentService
            .getRequestServices(requestId)
            .then(setRequestServices)
            .finally(() => setIsLoading(false));

    const getStandardName = () =>
        requestServiceDocumentService
            .getStandardName(
                request,
                { ...serviceConfig, extension: getFileExtension() },
                getSelectedService()
            )
            .then(setStandardName);

    const handleConfirm = async () =>
        Promise
            .resolve(setIsLoading(true))
            .then(() => onConfirm(document, getFinalConfig(), standardName))
            .finally(() => setIsLoading(false));

    const getFinalConfig = () => {

        const config = { ...serviceConfig }

        if (config.name)
            config.name = config.name + '.' + getFileExtension()

        return config;
    }

    const handleChange = (field, value, checkMainDocument) => {

        if (!checkMainDocument) {
            setServiceConfig({ ...serviceConfig, [field]: value });
            return;
        }

        const newConfig = { ...serviceConfig, [field]: value };

        const isServiceMainDocument = checkMainDocumentValue(newConfig);

        setServiceConfig({ ...newConfig, isServiceMainDocument });
    }

    const getFileExtension = () => {
        const nameArray = document.name.split('.');
        return nameArray[nameArray.length - 1];
    }

    const checkMainDocumentValue = (newConfig) =>
        (!newConfig.isVisibleCustomer || !newConfig.serviceId) ? false : newConfig.isServiceMainDocument

    const handleChangeService = (field, value) => {

        const newValue = value == 0 ? null : value;

        handleChange(field, newValue, true);
    }

    const getSelectedService = () =>
        requestServices.find(i => i.value == serviceConfig.serviceId) || {};

    const getMainDocumentCheckbox = () => {

        const serviceWithoutMainDocument = !getSelectedService().isDocumentRequired;

        if (!serviceConfig.serviceId || serviceWithoutMainDocument || !serviceConfig.isVisibleCustomer)
            return null;

        return (
            <Grid item>
                <FormField
                    name="isServiceMainDocument" field="Documento principal" type='checkbox'
                    currentValue={serviceConfig.isServiceMainDocument} clean
                    titleColor='black' titleSize={14} onChange={handleChange}
                    placeholder={`O documento é o(a) ${getSelectedService().name}?`}
                />
            </Grid>
        )
    }

    const getNameField = () => {

        if (standardName)
            return (
                <Grid item>
                    <Alert icon={false}>
                        <AlertTitle>
                            <strong>Nome do documento padrão:</strong>
                        </AlertTitle>
                        {standardName}
                    </Alert>
                </Grid>
            )

        if (!isEdit)
            return null;

        return (
            <FormField
                name="name" currentValue={serviceConfig.name}
                clean titleColor='black' titleSize={14} required
                field="Nome do documento" onChange={handleChange}
            />
        )
    }

    const getServiceSelection = () => {

        const serviceName = getSelectedService().name || '-'

        if (!loginService.isInternal())
            return (
                <Grid item>
                    <Alert severity='info'>
                        Documento do serviço de <strong>{serviceName}</strong>.
                    </Alert>
                </Grid>
            )

        return (
            <FormField
                name="serviceId" currentValue={(serviceConfig.serviceId || 0)}
                clean titleColor='black' titleSize={14} type='select'
                options={requestServices} field="Serviço do documento"
                onChange={handleChangeService}
            />
        )
    }

    return (
        <Dialog
            open={true} disableEscapeKeyDown
            maxWidth="xs" fullWidth onClose={handleClose}
        >
            <DialogContent>
                <Grid container direction='column' spacing={4}>

                    <Grid item>
                        <Typography variant='h5'>
                            Configurações de serviço
                        </Typography>
                    </Grid>

                    {getServiceSelection()}

                    <Grid item>
                        <FormField
                            name="isVisibleCustomer" titleColor='black' titleSize={14} type='checkbox'
                            field={serviceConfig.serviceId ? "Versão final" : "Visível para o cliente"}
                            currentValue={serviceConfig.isVisibleCustomer} clean
                            onChange={(f, v) => handleChange(f, v, true)}
                        />

                        <Collapse in={serviceConfig.isVisibleCustomer}>
                            <Alert severity='warning'>
                                {!serviceConfig.serviceId ?
                                    `O documento ficará disponível para o cliente 
                                    imediatamente! Esse recurso só deve ser usado 
                                    caso seja necessário mostrar documentos para 
                                    o cliente durante a produção dos serviços`
                                    : `O documento ficará disponível para o cliente 
                                    quando todos os serviços forem concluídos.`
                                }
                            </Alert>
                        </Collapse>
                    </Grid>

                    {getMainDocumentCheckbox()}

                    {getNameField()}

                </Grid>
            </DialogContent>

            <Divider />

            <DialogActions>

                <Button
                    variant="text"
                    onClick={handleClose}
                    disabled={isLoading}
                >
                    Cancelar
                </Button>

                <Button
                    variant="contained" disabled={isLoading}
                    color='primary' onClick={handleConfirm}
                >
                    {isLoading && (
                        <CircularProgress color='secondary'
                            size={18} style={{ marginRight: 8 }} />
                    )}

                    {isLoading ? 'Aguarde' : 'Confirmar'}
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default RequestDocumentsServiceConfigModal;