import { Avatar, IconButton as MuiIconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { ExitToApp, TuneOutlined, VpnKey } from '@material-ui/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { default as LoginService, default as loginService } from '../domain/services/login.service';
import EditPasswordDialog from './users/edit.password.component';
import { useTheme } from '../context/themeContext';
import { segmentations } from '../domain/constant/marketing/segmentation.constant';

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

function UserLogout() {

    const { updateTheme } = useTheme();

    const [anchorMenu, setAnchorMenu] = useState(null);
    const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);

    const handleDialogClose = () => {
        setIsPasswordDialogOpen(false);
    };

    const handleEditPasswordButtonClick = () => {
        setIsPasswordDialogOpen(true);
    };

    const history = useHistory();

    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleSignOut = async () => {
        LoginService.signOut();
        updateTheme(null);
        history.push('/login');
    };

    return (
        <React.Fragment>
            <Tooltip title={loginService.getUserAuthentication().name} placement="left">
                <IconButton
                    aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                >
                    <Avatar
                        style={{ width: '28px', height: '28px', fontSize: '14px', fontWeight: 600 }}
                        alt={loginService.getUserAuthentication().name}
                        src="/static/img/avatars/avatar-1.jpg"
                    />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                <MenuItem
                    style={{
                        fontWeight: '600',
                        borderBottom: '1px solid #d8d8d8',
                        cursor: 'default',
                    }}
                >
                    {loginService.getUserAuthentication().email}
                </MenuItem>
                {loginService.getUserAuthentication()?.customer?.segmentationKey === segmentations.INFINITY.key && (
                    <MenuItem
                        style={{ fontWeight: '600', cursor: 'pointer', color: 'rgb(97 97 97 / 90%)' }}
                        onClick={() => history.push('/profile#preferences')}
                        >
                        <TuneOutlined style={{ marginRight: '10px' }} /> Preferências
                    </MenuItem>
                )}
                <MenuItem
                    style={{ fontWeight: '600', cursor: 'pointer', color: 'rgb(97 97 97 / 90%)' }}
                    onClick={handleEditPasswordButtonClick}
                >
                    <VpnKey style={{ marginRight: '10px' }} /> Editar Senha
                </MenuItem>
                <MenuItem
                    style={{ fontWeight: '600', cursor: 'pointer', color: 'rgb(97 97 97 / 90%)' }}
                    onClick={handleSignOut}
                >
                    <ExitToApp style={{ marginRight: '10px' }} /> Sair
                </MenuItem>
            </Menu>
            <EditPasswordDialog isOpen={isPasswordDialogOpen} onClose={handleDialogClose} />
        </React.Fragment>
    );
}

export default UserLogout;
