import api from '../../services/api';

const baseUrl = 'marketing/announcement'

const markViewed = async (announcementId) =>
    api.post({ type: 'user', service: `${baseUrl}/${announcementId}/mark-viewed` });

const markViewedByKey = async (announcementKey, additionalInformation = null) =>
    api.post({ type: 'user', service: `${baseUrl}/${announcementKey}/mark-viewed-key`, data: { additionalInformation } });

const getAnnouncementAllowedDisplay = async (announcementKey, customerId) =>
    api.post({ type: 'user', service: `${baseUrl}/${announcementKey}/allowed-display`, data: { customerId } });

export default {
    markViewed,
    markViewedByKey,
    getAnnouncementAllowedDisplay,
}