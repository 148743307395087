
import Async from "../../components/Async";

import { BusinessCenter } from "@material-ui/icons";

const PackageView = Async(() => import('../../pages/financial/package'));
const TransactionsView = Async(() => import('../../pages/financial/transactions'));
const FinancialFaciliterInvoices = Async(() => import('../../pages/financial/faciliters/faciliter.invoice'));

const routes = {
    financial: {
        id: 'Financeiro',
        path: '/financial/packages',
        icon: <BusinessCenter />,
        children: [
            {
                id: 'packages',
                path: '/financial/packages',
                name: 'Pacotes',
                component: PackageView,
                roles: ['admin'],
            },
            {
                id: 'transactions',
                path: '/financial/transactions',
                name: 'Transações',
                component: TransactionsView,
                roles: ['admin'],
            },
            {
                id: 'invoices',
                path: '/financial/invoices',
                name: 'Faturas Faciliters',
                component: FinancialFaciliterInvoices,
                roles: ['financial'],
            },
        ],
        roles: ['admin'],
        component: null,
    },
}

const pages = Object.values(routes);

export default {
    routes,
    pages
}