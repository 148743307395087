import api from '../../services/api';

const removeExtraDebit = async (requestId, extraDebitType) =>
    api.put({ type: 'user', service: `requests/${requestId}/extra-debit/${extraDebitType}/remove` });

const chargeExtraDebit = async (requestId, extraDebitType) =>
    api.put({ type: 'user', service: `requests/${requestId}/extra-debit/${extraDebitType}/charge` });

export default {
    chargeExtraDebit,
    removeExtraDebit
}