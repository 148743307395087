import modalities from "../../../domain/constant/modalities.constant";

export const copyWriter = {
    factsAndFundamentals: {
        [modalities.analysis.key]: {
            name: 'Indicação de Petição',
            legend: `Importante: Neste serviço não responderemos perguntas, apenas identificaremos a petição cabível ao caso. Se precisar de estudo aprofundado escolha o serviço de parecer.`
        },
        [modalities.notion.key]: {
            name: 'Parecer',
            legend: `Esse documento responde aos questionamentos dos clientes, assim é fundamental que traga todos os quesitos que devem ser respondidos em seu parecer, de forma que o redator possa transmitir para o documento as respostas necessárias com as devidas fundamentações legais.`
        },
        [modalities.contract.key]: {
            name: 'Contrato',
            title: 'Informações sobre o contrato',
            legend: `Neste documento é necessário que indique as cláusulas que obrigatoriamente deverão constar em seu contrato, assim é fundamental que traga todos os pontos que devem constar em sua minuta, de forma que o redator possa transmitir para o contrato as avenças entre as partes.`
        },
        [modalities.initial.key]: {
            name: 'Inicial',
            legend: `Neste campo você nos contará um pouco sobre o caso e o que gostaria em sua minuta. Isso nos guiará na criação de uma petição personalizada para você. É rapidinho e suas informações garantirão que sua minuta atenda às suas necessidades. Vamos lá, só vai levar um instante!`
        },
        [modalities.contestation.key]: {
            name: 'Contestação',
            legend: `Neste campo você nos contará um pouco sobre o caso e o que gostaria em sua minuta. Isso nos guiará na criação de uma petição personalizada para você. É rapidinho e suas informações garantirão que sua minuta atenda às suas necessidades. Vamos lá, só vai levar um instante!`
        },
        [modalities.manifestation.key]: {
            name: 'Manifestação',
            legend: `Neste campo você nos contará um pouco sobre o caso e o que gostaria em sua minuta. Isso nos guiará na criação de uma petição personalizada para você. É rapidinho e suas informações garantirão que sua minuta atenda às suas necessidades. Vamos lá, só vai levar um instante!`
        },
        [modalities.counterReasons.key]: {
            name: 'Contrarrazões',
            legend: `Neste campo você nos contará um pouco sobre o caso e o que gostaria em sua minuta. Isso nos guiará na criação de uma petição personalizada para você. É rapidinho e suas informações garantirão que sua minuta atenda às suas necessidades. Vamos lá, só vai levar um instante!`
        },
        [modalities.resources.key]: {
            name: 'Recursos',
            legend: `Neste campo você nos contará um pouco sobre o caso e o que gostaria em sua minuta. Isso nos guiará na criação de uma petição personalizada para você. É rapidinho e suas informações garantirão que sua minuta atenda às suas necessidades. Vamos lá, só vai levar um instante!`
        },
        [modalities.calculation.key]: {
            name: 'Cálculo',
            legend: `Os cálculos tem questões específicas que devem ser ressaltadas, assim é fundamental que traga todos os pontos necessários para o desenvolvimento.`
        },
        [modalities.hearing.key]: {
            name: 'Audiência Virtual',
            legend: ``
        },
        [modalities.hearingInPerson.key]: {
            name: 'Audiência Presencial',
            legend: ``
        },
        [modalities.hearingAnalysis.key]: {
            name: 'Audiência Virtual',
            legend: ``
        },
        default: {
            title: 'Informações sobre o caso'
        }
    },
    attachaments: {
        [modalities.analysis.key]: {
            legend: `
            <div style="font-size: 0.6964285714285714rem; color: rgba(0, 0, 0, 0.54); font-weight: 400; line-height: 1.66;">
                Clique em "Anexar Documentos". Aceitamos PDFs, JPGs, PNGs, documentos do Word e vídeos. 
                Certifique-se de incluir todos os documentos relevantes para que possamos recomendar a melhor petição com base em suas informações. 
                <strong style="color: black;">Atenção: Essa etapa é muito importante que seja feita, para evitar atrasos na entrega da sua solicitação, ok?</strong>
            </div>`
        },
        [modalities.initial.key]: {
            legend: `
            <div style="font-size: 0.6964285714285714rem; color: rgba(0, 0, 0, 0.54); font-weight: 400; line-height: 1.66;">
            Clique em 'Anexar Documentos'. Aceitamos PDFs, JPGs, PNGs, documentos do Word e vídeos. 
            Certifique-se de incluir todos os documentos relevantes para que possamos entregar a minuta completa e personalizada.
                <strong style="color: black;">Atenção: Essa etapa é muito importante que seja feita, para evitar atrasos na entrega da sua solicitação, ok?</strong>
            </div>              
            `
        },
        [modalities.hearing.key]: {
            legend: `
            <div style="font-size: 0.6964285714285714rem; color: rgba(0, 0, 0, 0.54); font-weight: 400; line-height: 1.66;">
            Clique em "Anexar Documentos". Aceitamos PDFs, JPGs, PNGs, documentos do Word e vídeos. Certifique-se de incluir todos os documentos para que o audiencista possa estudar o caso antes da audiência.
                <strong style="color: black;">Atenção: Essa etapa é muito importante que seja feita, para evitar atrasos na entrega da sua solicitação, ok?</strong>
            </div>              
            `
        },
        [modalities.hearingInPerson.key]: {
            legend: `
            <div style="font-size: 0.6964285714285714rem; color: rgba(0, 0, 0, 0.54); font-weight: 400; line-height: 1.66;">
            Clique em "Anexar Documentos". Aceitamos PDFs, JPGs, PNGs, documentos do Word e vídeos. Certifique-se de incluir todos os documentos para que o audiencista possa estudar o caso antes da audiência.
                <strong style="color: black;">Atenção: Essa etapa é muito importante que seja feita, para evitar atrasos na entrega da sua solicitação, ok?</strong>
            </div>              
            `
        },
        [modalities.hearingAnalysis.key]: {
            legend: `
            <div style="font-size: 0.6964285714285714rem; color: rgba(0, 0, 0, 0.54); font-weight: 400; line-height: 1.66;">
            Clique em "Anexar Documentos". Aceitamos PDFs, JPGs, PNGs, documentos do Word e vídeos. Certifique-se de incluir todos os documentos para que o audiencista possa estudar o caso antes da audiência.
                <strong style="color: black;">Atenção: Essa etapa é muito importante que seja feita, para evitar atrasos na entrega da sua solicitação, ok?</strong>
            </div>              
            `
        },
        default: {
            legend: `
            <div style="font-size: 0.6964285714285714rem; color: rgba(0, 0, 0, 0.54); font-weight: 400; line-height: 1.66;">
                Clique em 'Anexar Documentos'. Aceitamos PDFs, JPGs, PNGs, documentos do Word e vídeos. 
                Certifique-se de incluir todos os documentos relevantes para que possamos entregar a minuta completa e personalizada.
                <strong style="color: black;">Atenção: Essa etapa é muito importante que seja feita, para evitar atrasos na entrega da sua solicitação, ok?</strong>
            </div>`
        }
    },
    processInformation: {
        [modalities.hearing.key]: {
            title: 'Dados da Audiência',
            legend: 'Neste campo você vai inserir os dados para representação em sua audiência.'
        },
        [modalities.hearingAnalysis.key]: {
            title: 'Dados da Audiência',
            legend: 'Neste campo você vai inserir os dados para representação em sua audiência.'
        },
        [modalities.hearingInPerson.key]: {
            title: 'Dados da Audiência',
            legend: 'Neste campo você vai inserir os dados para representação em sua audiência.'
        },
        default: {
            title: 'Dados do processo',
            legend: 'Neste campo você vai inserir os dados do processo para personalizarmos a sua petição.'
        }
    },
    parts: {
        [modalities.contract.key]: {
            legend: `Quem são as partes no contrato?`,
            addButtonLabel: 'Clique no botão "Adicionar" para incluir as partes do contrato. Você incluirá uma parte por vez.'
        },
        default: {
            legend: 'Quem são as partes no processo?',
            addButtonLabel: 'Clique no botão "Adicionar" para incluir as partes do processo. Você incluirá uma parte por vez.'
        }
    }
};

