import api from '../api';

const findByRequestId = async (requestId) =>
    api.get({ type: 'user', service: `requests/customer-rating/request/${requestId}` });

const findByCustomerId = async (customerId, data) =>
    api.post({ type: 'user', service: `requests/customer-rating/customer/${customerId}`, data });

const findAnalysis = async (requestId) =>
    api.get({ type: 'user', service: `customers/customer-analysis-rating/${requestId}` });

const findAllPendingRatings = async (customerId) =>
    api.get({ type: 'user', service: `requests/customer-rating/${customerId}/pending` });

const checkIfRatingIsPending = async (requestId) =>
    api.get({ type: 'user', service: `requests/customer-rating/${requestId}/check-pending` });

const save = async (result) =>
    api.post({ type: 'user', service: `requests/customer-rating`, data: result });

const getRequestRatings = (requestId) =>
    Promise
        .all([
            findByRequestId(requestId).then(res => res.data).catch(() => []),
            findAnalysis(requestId).then(res => res.data).catch(() => null)
        ])
        .then(formatRatings);

const formatRatings = ([requestRatings, legacyAnalysisRating]) => {

    if (!legacyAnalysisRating)
        return requestRatings;

    const formatedLegacyRating = {
        ...legacyAnalysisRating,
        value: legacyAnalysisRating.score,
        isLegacyAnalysis: true
    }

    return [...requestRatings, formatedLegacyRating];
}

export default {
    findByCustomerId,
    findAnalysis,
    findAllPendingRatings,
    checkIfRatingIsPending,
    save,
    getRequestRatings
}