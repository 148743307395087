import moment from "moment";

const formatDateTime = (datetime, format = 'DD/MM/YYYY HH:mm', useLocalTime = false) =>
    moment(datetime).utc(useLocalTime).format(format)

const getTimeDifference = (unit, datetime1, datetime2) =>
    moment(datetime2).utc().diff(moment(datetime1).utc(), unit);

const getDate = (date) => moment(date);

const momentInstance = () => moment;

const isSameDate = (initDate, finalDate, unit) => {

    if (!initDate || !finalDate)
        return false;

    return moment(moment(initDate).format('YYYY-MM-DD')).isSame(moment(finalDate).format('YYYY-MM-DD'), unit);
}

const getTimeDifferenceUntilDate = (initialDate, finalDate, defaultText = "Solicitação realizada") => {

    const initial = moment(initialDate);
    const final = moment(finalDate);

    const diff = final.diff(initial);

    if (diff < 0) {
        return defaultText;
    }

    const duration = moment.duration(diff);

    let timeKey = 'belowAHour';
    timeKey = duration.asHours() >= 1 ? 'overAHour' : timeKey;
    timeKey = duration.asDays() >= 1 ? 'overADay' : timeKey;

    const timeTexts = {
        overADay: time => "Em " + Math.floor(time.asDays()) + " dias",
        overAHour: time => "Em " + Math.floor(time.asHours()) + " horas e " + time.minutes() + " minutos",
        belowAHour: time => "Em " + time.minutes() + " minutos",
    };

    return timeTexts[timeKey](duration);
}

const getBusinessDatesCount = (date) => {
    let count = 0;
    const curDate = new Date();
    curDate.setHours(0, 0, 0, 0);
    const endDate = new Date(date);
    endDate.setHours(0, 0, 0, 0);

    while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
        curDate.setDate(curDate.getDate() + 1);
    }
    return count;
};

const addBusinessDays = (days) => {
    const date = new Date();
    let addedDays = 0;

    while (addedDays < days) {
        date.setDate(date.getDate() + 1);
        const dayOfWeek = date.getDay();

        if (dayOfWeek !== 0 && dayOfWeek !== 6)
            addedDays++;
    }

    return date;
};

export default {
    formatDateTime,
    getTimeDifference,
    isSameDate,
    getTimeDifferenceUntilDate,
    getDate,
    getBusinessDatesCount,
    momentInstance,
    addBusinessDays
};