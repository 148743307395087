import repository from '../../repositories/financial/extra.debit.repository';

const removeExtraDebit = async (requestId, extraDebitType) =>
    repository.removeExtraDebit(requestId, extraDebitType)
        .then(res => res.data);

const chargeExtraDebit = async (requestId, extraDebitType) =>
    repository.chargeExtraDebit(requestId, extraDebitType)
        .then(res => res.data);

export default {
    chargeExtraDebit,
    removeExtraDebit
}