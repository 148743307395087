export const formatNumberCurrency = (value) =>
    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);

export const formatNumberCurrencyWithoutSymbol = (value) =>
    new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value);

export const getFormatCellphone = (cellphone) =>
    cellphone.replace(/-|\s|\(|\)/gi, "").replace('+55', '');

export const isNullOrEmpty = (value) =>
    (value === undefined || value === null || value === "")

export const isNullOrUndefined = (value) =>
    (value === undefined || value === null)

export const descendingOrderComparator = (a, b, orderBy) => {
    if (orderBy === 'price') {
        if (Number(b[orderBy]) < Number(a[orderBy])) return -1;
        if (Number(b[orderBy]) > Number(a[orderBy])) return 1;
    } else {
        if (b[orderBy] < a[orderBy]) return -1;
        if (b[orderBy] > a[orderBy]) return 1;
    }
    return 0;
}

export const getComparator = (order, orderBy) =>
    order === "desc"
        ? (a, b) => descendingOrderComparator(a, b, orderBy)
        : (a, b) => -descendingOrderComparator(a, b, orderBy);

export const sortList = (list, comparator) => {
    const rows = list.map((el, index) => [el, index]);

    rows.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        return (order !== 0) ? order : a[1] - b[1];
    });

    return rows.map((el) => el[0]);
}

export const removeFields = (input, fields) => {
    let copy = { ...input };
    for (const x of fields) {
        delete copy[x];
    }
    return copy;
}

export const basicValidation = (fieldNames, input) => {

    for (const fieldName of fieldNames)
        if (!input[fieldName])
            return false;

    return true;
}

export const changeObjectInArray = (values, changedValue) => {

    const valuesCopy = [...values];

    const index = valuesCopy.findIndex(value => (value.id == changedValue.id));

    if (index > -1) {
        valuesCopy[index] = { ...changedValue };
        return [...valuesCopy];
    }

    valuesCopy.push({ ...changedValue });
    return [...valuesCopy];
}

export const changeItemInList = (values, changedValue) => {

    const valuesCopy = [...values];

    const index = valuesCopy.findIndex(value => (value.id == changedValue.id));
    valuesCopy[index] = changedValue;

    return [...valuesCopy];
}

export const removeItemInList = (values, item) => {

    const valuesCopy = [...values];

    valuesCopy.splice(valuesCopy.findIndex(value => (value.id == item.id)), 1);

    return [...valuesCopy];
}

export const checkIfArraysIsEquals = (array1, array2) =>
    (JSON.stringify(array1) == JSON.stringify(array2))

export const isObjectEmpty = (value) =>
    Object.keys(value).length === 0;

export const flattenObject = (value, res = {}) => {

    for (const key of Object.keys(value)) {

        if (isNullOrEmpty(value[key]))
            continue;

        if (typeof value[key] === 'object' && !Array.isArray(value[key])) {
            flattenObject(value[key], res);
            continue;
        }

        res[key] = value[key];
    }

    return res;
}

const isArrayEmpty = (value = []) =>
    Array.isArray(value) && !value.length;

export const getMissingFields = (values = {}, requiredFields) => {

    const missingFields = [];

    for (const field of requiredFields)
        (isNullOrEmpty(values[field]) || isArrayEmpty(values[field])) && missingFields.push(field);

    return missingFields;
}

const isValidNumber = (value) =>
    !isNaN(value)

const accessObjValueByCompositeKey = (obj, path) => {

    const newPath = path.split('.');
    let value = { ...obj };

    while (newPath.length) {
        if (typeof value !== 'object' || value == null)
            return undefined;

        value = value[newPath.shift()];

    }

    return value;
}

const scrollToBottom = () => {

    const scrollHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight
    );

    window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth'
    });
}

export const hexToRgb = (hex) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 7) {
        r = parseInt(hex.slice(1, 3), 16);
        g = parseInt(hex.slice(3, 5), 16);
        b = parseInt(hex.slice(5, 7), 16);
    } else if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    return { r, g, b };
};

export const rgbToHex = (r, g, b) => {
    return `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`;
};

export const isColorTooLight = (hex) => {
    try {
        const { r, g, b } = hexToRgb(hex);
        
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        
        const isTooLight = luma > 60;

        return isTooLight;
    } catch {
        return hex;
    }
}

export default {
    formatNumberCurrency,
    formatNumberCurrencyWithoutSymbol,
    getFormatCellphone,
    isNullOrEmpty,
    isNullOrUndefined,
    getComparator,
    sortList,
    removeFields,
    basicValidation,
    changeObjectInArray,
    changeItemInList,
    removeItemInList,
    isObjectEmpty,
    checkIfArraysIsEquals,
    flattenObject,
    getMissingFields,
    isValidNumber,
    accessObjValueByCompositeKey,
    scrollToBottom,
    hexToRgb,
    rgbToHex,
    isColorTooLight,
}