import { useEffect, useState } from 'react';

import NewRequestModalForm from './new.request.modal.form.component';
import SimpleModal from '../shared/SimpleModal';

import ReactPixel from 'react-facebook-pixel';
import { requestRegistrationPixelID, options } from '../../domain/constant/pixel.constant';

const NewRequestModal = ({
    isOpen,
    onClose,
    setAlert,
    initialValue = null,
    openTutorial,
}) => {

    ReactPixel.init(requestRegistrationPixelID, {}, options);

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!isOpen)
            return
        ReactPixel.trackCustom('InitiateRequestRegistration', {});
    }, [isOpen])

    return (
        <SimpleModal
            title="Nova Solicitação"
            subtitle={(
                <button
                    className='text-[#0007] hover:text-[#0009]'
                    onClick={openTutorial}
                >
                    Precisa de ajuda? Clique aqui
                </button>
            )}
            open={isOpen}
            disableEscapeKeyDown={isSubmitting}
            onClose={onClose}
            disableBackdropClick
            maxWidth='sm'
        >
            <NewRequestModalForm
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                setAlert={setAlert}
                initialValue={initialValue}
                onClose={onClose}
            />
        </SimpleModal>
    );
}

export default NewRequestModal;