import { createContext, useContext, useEffect, useState } from 'react';
import { THEMES } from '../material-app-pro/src/constants';
import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider as SCThemeProvider } from 'styled-components/macro';
import createTheme from '../material-app-pro/src/theme';
import loginService from '../domain/services/login.service';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {

    const user = loginService.getUserAuthentication();

    const [theme, setTheme] = useState(user?.customer?.theme?.name || THEMES.LIGHT);

    const updateTheme = (newTheme) => {
        if (!newTheme) {
            setTheme(THEMES.LIGHT);
            return;
        }
        
        setTheme(newTheme);
    };

    return (
        <ThemeContext.Provider value={{ theme, updateTheme }}>
            <MuiThemeProvider theme={createTheme(theme)}>
                <SCThemeProvider theme={createTheme(theme)}>
                    {children}
                </SCThemeProvider>
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
