import { useState, useRef } from 'react';

import { IconButton, Tooltip, Box } from '@material-ui/core';
import { FastForward } from "@material-ui/icons";

const AudioPlayer = ({ src }) => {

    const [playRate, setPlayRate] = useState(1);

    const playerRef = useRef(null);

    const getNextPlayRate = () => playRate < 2 ? playRate + 0.5 : 1

    const togglePlaybackRate = () => {

        const newPlaybackRate = getNextPlayRate();

        if (!playerRef.current)
            return;

        playerRef.current.playbackRate = newPlaybackRate;
        setPlayRate(newPlaybackRate);
    };

    return (
        <Box display='flex' alignItems='center' gridGap={10} style={{ pointerEvents: 'auto' }}>

            <audio
                ref={playerRef}
                src={src}
                controls
                style={{ width: '300px' }}
            />

            <Tooltip title={`Acelerar: ${getNextPlayRate()}x`}>
                <IconButton
                    onClick={togglePlaybackRate} size='small'
                    color={playRate === 1 ? 'default' : 'primary'}
                >
                    {
                        playRate === 1 ?
                            <FastForward />
                            : <span style={{ width: 22, fontSize: 12 }}>{playRate}x</span>
                    }
                </IconButton>
            </Tooltip>

        </Box>
    )
}

export default AudioPlayer;