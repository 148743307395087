

import { useState, useEffect } from 'react';
import moment from 'moment';

import swal from 'sweetalert2';

import { Grid, Typography, makeStyles } from '@material-ui/core';

import FormField from '../FormField';
import AdvanceDueDate from './advance.due.date';

import loginService from '../../domain/services/login.service';
import solicitationService from '../../domain/services/solicitation/solicitation.service';

import stepsSolicitation from '../../domain/constant/steps.solicitation.constant';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gap: 20,
        alignItems: 'flex-end',
        '@media (max-width: 645px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    }
}));

export default function RequestAllServicesDueDate({
    mainRequestId, request, refresh, setAlert, setValue, fieldProps
}) {

    const classes = useStyles();

    const [allServicesDueDate, setAllServicesDueDate] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => getAllServicesDueDate(request.allServicesDueDate), []);

    useEffect(() => setAllServicesDueDate(request.allServicesDueDate), [request.allServicesDueDate]);

    const getAllServicesDueDate = () =>
        solicitationService
            .getAvailableDueDate(request.mainId || request.id)
            .then(({ data }) => setAllServicesDueDate(data.allServicesDueDate));

    const editAllServicesDueDateSkipingValidation = (data = {}, value, oldDueDate) => {

        setLoading(false);

        if (!data.canSkipValidation) {
            setAllServicesDueDate(oldDueDate);
            setAlert((data.message || 'Não foi possível alterar a data de entrega de todos os serviços solicitação!'), "error");
            return
        }

        swal.fire({
            title: data.message,
            html: `Tem certeza que deseja alterar a data de entrega de todos os serviços da solicitação mesmo assim? Lembrando que alterar o prazo não altera a antecipação realizada, para isso é necessário editar a antecipação!`,
            icon: "warning",
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
        })
            .then(result => {

                if (result.isConfirmed)
                    return editAllServicesDueDate(value, oldDueDate, data.canSkipValidation);

                setAllServicesDueDate(oldDueDate);
            })
            .catch(err => {
                setAllServicesDueDate(oldDueDate);
                setAlert(err?.response?.data?.message || "Erro ao salvar informação", "error")
            });
    }

    const editAllServicesDueDate = async (value, oldDueDate, canSkipValidation = false) => {

        setLoading(true);

        await solicitationService
            .editAllServicesDueDate(mainRequestId, { allServicesDueDate: value, canSkipValidation })
            .then(res => res.data)
            .then(data => {
                if (data.changedDueDate)
                    setValue('dueDate', value)

                setValue('allServicesDueDate', value)
            })
            .then(() => setAlert("Salvo com sucesso", "success"))
            .catch(err => editAllServicesDueDateSkipingValidation(err?.response?.data, value, oldDueDate))
            .finally(() => setLoading(false));
    }

    const getCustomerAllServicesDueDate = () => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ color: '#000000DE' }}>A solicitação será entregue em</Typography>
            <Typography color='secondary' style={{ fontWeight: 500, fontSize: 20 }}>{moment(allServicesDueDate).format("DD [de] MMMM [de] YYYY [às] HH:mm")}</Typography>
        </div>
    )

    const getAllServicesDueDateField = () => {

        const component = {
            internal: () => (
                <Grid item>
                    <FormField
                        {...fieldProps}
                        type='datetime' required
                        name="allServicesDueDate"
                        field="Entrega de todos os serviços"
                        currentValue={allServicesDueDate}
                        onChange={(name, value) => editAllServicesDueDate(value, allServicesDueDate)}
                        disabled={loading}
                    />
                </Grid>
            ),
            customer: getCustomerAllServicesDueDate,
            customerUser: getCustomerAllServicesDueDate,
        }

        const componentToRender = component[loginService.getUserAuthentication().source]

        return componentToRender ? componentToRender() : null;
    }

    const getAdvanceButton = () => {

        const canAdvance = ![stepsSolicitation.CONCLUDED, stepsSolicitation.REVOKED].includes(request.status);

        if (!canAdvance)
            return null;

        return (
            <div style={{ paddingBottom: 3 }}>
                <AdvanceDueDate mainRequestId={mainRequestId}
                    request={request} onRefreshRequest={refresh} />
            </div>
        )
    }

    if (!allServicesDueDate)
        return null;

    return (
        <div className={classes.container}>
            {request.status == stepsSolicitation.CONCLUDED && request.concludedAt ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ color: '#000000DE' }}>A solicitação foi entregue em</Typography>
                    <Typography color='secondary' style={{ fontWeight: 500, fontSize: 20 }}>{moment(request.concludedAt
                    ).format("DD [de] MMMM [de] YYYY [às] HH:mm")}</Typography>
                </div>
            ) : getAllServicesDueDateField()}
            {getAdvanceButton()}
        </div>
    )
}